import { Link } from "react-router-dom";

function SchoolAdminMP() {
    return (
        <div className="app-content">
            <div className="app-content-header" style={{ padding: '20px 0' }}>
                <h1 className="app-content-headerText">Reports</h1>
                {/* <a class="primary_btn" type="button" href="studentPost.html">back */}
            </div>
            <div className="products-area-wrapper tableView">
                <div className="products-header">
                    <div className="products-row1">
                        <section>
                            <div className="student">
                                <canvas id="myChart" style={{ width: '100%', maxWidth: '600px' }} />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default SchoolAdminMP;
