import { useMemo } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllClubs } from "../../API";
import {
  createOrUpdateClub,
  getClubsOrMembers,
  joinOrExitClub,
} from "../../API/clubs";
import Loader from "../../components/Loader";

function StudentClub() {
  const [clubs, setClubs] = useState(null);
  const [user, setUser] = useState(
    JSON.parse(window.localStorage.getItem("user"))
  );
  const [userClubs, setUserClubs] = useState(null);
  const userClubIds = useMemo(() => {
    return (userClubs || []).map((userClub) => userClub.club_id);
  }, [userClubs]);
  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  const getClubs = async () => {
    try {
      const res = await getAllClubs({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setClubs(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const getUserClubs = async (data = {}) => {
    const tempData = {
      user_id: data?.id || user?.id,
      club_id: data?.clubId || -1,
    };
    try {
      const res = await getClubsOrMembers(tempData);
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setUserClubs(res.data);
      } else {
        setUserClubs([]);
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const handleJoinOrExitClub = async (clubId, deleted) => {
    try {
      const res = await joinOrExitClub({
        club_id: clubId,
        id: user?.id,
        deleted: deleted || 0,
      });
      if (res.response === "success" || res.response === "SUCCESS") {
        toast(res.message || res.Message);
        getClubs();
        getUserClubs({ id: user?.id });
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  useEffect(() => {
    getClubs();
    getUserClubs({ id: user?.id });
  }, []);
  const handleCreateOrUpdateClubs = async (data) => {
    const tempData = {
      club_id: data.id,
      club_name: data.name,
      club_description: data.description,
      created_by: data.owner_id || user?.id,
      is_deleted: data.delete || 1,
    };
    try {
      const res = await createOrUpdateClub(tempData);
      if (
        res.reponse === "success" ||
        res.reponse === "SUCCESS" ||
        res.response == "success" ||
        res.resposne == "SUCCESS" ||
        res.response == "SUCCESS"
      ) {
        toast.success("Deleted successfully");
        getClubs();
      } else {
        toast(res.Message || "Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <>
      {clubs && userClubs ? (
        <div className="app-content">
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            <h1 className="app-content-headerText">Club</h1>
            <Link className="primary_btn" type="button" to="add">
              add
            </Link>
          </div>
          <div className="products-area-wrapper tableView">
            <div className="products-header">
              <div className="products-row1">
                <section>
                  <div className="student_club">
                    {clubs.map((club, i) => (
                      <div className="service_card_wrapper">
                        <div className="pic4" />
                        <p className="card_header">{club.name}</p>
                        <p className="card_content">
                          {club.description.length > 15
                            ? club.description.slice(0, 15 - 1) + "...."
                            : club.description}
                        </p>
                        {user?.id == club.owner_id ? (
                          <>
                            <div className>
                              <Link state={{ club }} to="edit">
                                <div className="card_readmore">
                                  <b /> Edit{" "}
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  />
                                </div>
                              </Link>
                            </div>
                            <a href="#">
                              <div
                                className="card_readmore"
                                onClick={() => handleCreateOrUpdateClubs(club)}
                              >
                                <b>Delete</b>{" "}
                                <i className="fas fa-angle-right" />
                              </div>
                            </a>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => {
                                userClubIds.filter((x) => x == club.id).length >
                                0
                                  ? handleJoinOrExitClub(club.id, 1)
                                  : handleJoinOrExitClub(club.id);
                              }}
                              className="card_readmore"
                            >
                              <b>
                                {userClubIds.filter((x) => x == club.id)
                                  .length > 0
                                  ? "Exit"
                                  : "Join"}
                              </b>{" "}
                              <i className="fas fa-angle-right" />
                            </div>
                            <Link state={{ club: club }} to="read">
                              <div className="card_readmore">
                                <b>Read more</b>{" "}
                                <i className="fas fa-angle-right" />
                              </div>
                            </Link>
                          </>
                        )}
                      </div>
                    ))}
                    {/* <div className="service_card_wrapper">
                      <div className="pic4" />
                      <p className="card_header">Party</p>
                      <p className="card_content">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                      </p>
                      <Link to="read">
                        <div className="card_readmore read_club">
                          Read <i className="fas fa-angle-right" />
                        </div>
                      </Link>
                      <a href="#" onclick="alert('succesfully accepted')">
                        <div className="card_readmore read_club ">
                          Accept <i className="fas fa-angle-right" />
                        </div>
                      </a>
                      <a href="#" onclick="alert('Rejected')">
                        <div className="card_readmore read_club ">
                          Reject <i className="fas fa-angle-right" />
                        </div>
                      </a>
                    </div>
                    <div className="service_card_wrapper">
                      <div className="pic5" />
                      <p className="card_header">Programming</p>
                      <p className="card_content">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                      </p>
                      <a href="#" onclick="alert('succesfully deleted')">
                        <div className="card_readmore read_club delete">
                          delete <i className="fas fa-angle-right" />
                        </div>
                      </a>
                    </div>
                    <div className="service_card_wrapper">
                      <div className="pic6" />
                      <p className="card_header">Music</p>
                      <p className="card_content">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                      </p>
                      <Link to="read">
                        <div className="card_readmore read_club ">
                          Read <i className="fas fa-angle-right" />
                        </div>
                      </Link>
                      <a href="#" onclick="alert('succesfully accepted')">
                        <div className="card_readmore read_club ">
                          Accept <i className="fas fa-angle-right" />
                        </div>
                      </a>
                      <a href="#" onclick="alert('Rejected')">
                        <div className="card_readmore read_club ">
                          Reject <i className="fas fa-angle-right" />
                        </div>
                      </a>
                    </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default StudentClub;
