/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
function Footer() {

    return (
        <div>
            <section>
                <footer className="footer_wrapper">
                    <div className="footer">
                        <div className="footer_primary">
                            {/* <div className="footer_logo">
                                <img src={owner} style={{ height: '80px' }} />
                            </div> */}
                            <div className='connectwithus'>
                                CONNECT WITH US
                            </div>
                            <div className="social_media">
                                <i className="fa-brands fa-twitter"></i>
                                <i className="fa-brands fa-facebook-f"></i>
                                <i className="fa-brands fa-linkedin-in"></i>
                                <i className="fa-brands fa-youtube"></i>
                            </div>
                        </div>
                        <div className="footer_secondary">
                            <div className="footer_more">
                                <h1>More MySite</h1>
                                <a href="">Media</a>
                                <a href="">Events</a>
                                <a href="">Program</a>
                                <a href="">Newsletter</a>
                                <a href="">Deals</a>
                            </div>
                            <div className="footer_about">
                                <h1>About MySite</h1>
                                <a href="">Partner with us</a>
                                <a href="">Terms and Conditions</a>
                                <a href="">Cookie Statement</a>
                                <a href="">Privacy Statement</a>
                                <a href="">Editorial Policy</a>
                            </div>
                        </div>

                    </div>

                </footer>
            </section>
        </div>
    );
}

export default Footer;
