import { BASE_URL_API } from "./base";

export const getAllSchools = async (data) => {
  const tempData = {
    school_id: data.id || "-1",
  };
  const rawResponse = await fetch(`${BASE_URL_API}/getschools.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();
  return content;
};
export const createOrUpdateSchools = async (data) => {
  const tempData = {
    school_id: data.id || "-1",
    school_name: data.name,
    school_address: data.address,
    phone_number: data.phone,
    is_deleted: data.deleted || 0,
  };
  const rawResponse = await fetch(`${BASE_URL_API}/createorupdateschool.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();
  return content;
};
