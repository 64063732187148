import { Link, useLocation, useNavigate } from "react-router-dom";
import club from "../../assets/club1.png";

function PostRead() {
  const location = useLocation();
  const { post } = location.state;
  const navigate = useNavigate();
  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">Post</h1>
        <button
          className="primary_btn"
          type="button"
          onClick={() => navigate(-1)}
        >
          back
        </button>
      </div>
      <div className="products-area-wrapper tableView">
        <div className="products-header">
          <div className="products-row1">
            <section>
              <div
                className="student"
                style={{
                  backgroundColor: "#fff",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <div className="read_post">
                  <img src={club} alt="img" className="read_post_img" />
                  <h1 style={{ padding: "20px 0", fontSize: "40px" }}>
                    {post.name}
                  </h1>
                  <p>{post.description}</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostRead;
