import { BASE_URL_API } from "./base";

export const getAllUsers = async (data) => {
  console.log(data);
  const tempData = {
    user_id: data.id || "-1",
    role_id: data.role || "-1",
    school_id: data.school || "-1",
    created_start_date: data.createdDate || "1000-01-01",
    created_end_date: data.endDate || "1000-01-01",
  };
  const rawResponse = await fetch(`${BASE_URL_API}/getusers.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();

  return content;
};
