import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import "../../CSS/studentchat.css";

function Chat() {
  const [state, setState] = useState({ message: "", name: "" });
  const [chat, setChat] = useState([]);
  const [user] = useState(JSON.parse(window.localStorage.getItem("user")));
  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = io("http://localhost:4000", {
      transports: ["websocket"],
    });

    socketRef.current.on("message", ({ name, message }) => {
      console.log("inside ");
      setChat([...chat, { name, message }]);
    });
    socketRef.current.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
    // socketRef.current.on("error", function (data) {
    //   console.log(data || "error");
    // });

    // socketRef.current.on("connect_failed", function (data) {
    //   console.log(data || "connect_failed");
    // });
    return () => socketRef.current.disconnect();
  }, [chat]);

  const onTextChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onMessageSubmit = (e) => {
    console.log(socketRef.current);
    let { name, message } = state;
    if (!name) {
      name = user.name;
    }
    socketRef.current.emit("message", { name, message });
    e.preventDefault();
    setState({ message: "", name });
  };

  const renderChat = () => {
    return chat.map(({ name, message }, index) => (
      <div key={index}>
        <h3>
          {name}: <span>{message}</span>
        </h3>
      </div>
    ));
  };

  return (
    <div className="chat-card">
      <form className="chat-form" onSubmit={onMessageSubmit}>
        <h1>Messenger</h1>
        {/* <div className="name-field">
          <textarea
            name="name"
            onChange={(e) => onTextChange(e)}
            value={state.name}
            label="Name"
            placeholder="Name"
          />
        </div> */}
        <div>
          <textarea
            name="message"
            onChange={(e) => onTextChange(e)}
            value={state.message}
            id="outlined-multiline-static"
            variant="outlined"
            label="Message"
            placeholder="message"
          />
        </div>
        <button>Send Message</button>
      </form>
      <div className="render-chat">
        <h1>Chat Log</h1>
        {renderChat()}
      </div>
    </div>
  );
}

export default Chat;
