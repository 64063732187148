/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Routes, Route, Link } from "react-router-dom";
import aboutImg from "../assets/about.jpg";
import club1 from "../assets/club1.png";
import club2 from "../assets/club2.png";
import Footer from "../components/footer";
import Header from "../components/header";
import projs from "../Js/pro";

function Blog() {
  return (
    <div>
      <script
        src="https://kit.fontawesome.com/82f797382f.js"
        crossOrigin="anonymous"
      ></script>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <link rel="stylesheet" href="owlcarousel/owl.carousel.min.css"></link>
      <script src={projs} defer></script>
      <Header />
      <section className="page_content">
        <section className="about_hero_section">
          <div
            className="about_hero_div"
            style={{ backgroundImage: `url(${aboutImg})` }}
          >
            <div className="header">
              <h2>Blog</h2>
            </div>
          </div>
        </section>

        <section className="about_us_section1">
          <div className="row">
            <div className="leftcolumn">
              <div className="card">
                <h2>TITLE HEADING</h2>
                <h5>Title description, Dec 7, 2017</h5>
                <div className="fakeimg">
                  <img src={club1} alt="" />
                </div>
                <p>Some text..</p>
                <p>
                  Sunt in culpa qui officia deserunt mollit anim id est laborum
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco.
                </p>
              </div>
              <div className="card">
                <h2>TITLE HEADING</h2>
                <h5>Title description, Sep 2, 2017</h5>
                <div className="fakeimg">
                  <img src={club2} alt="" />
                </div>
                <p>Some text..</p>
                <p>
                  Sunt in culpa qui officia deserunt mollit anim id est laborum
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </div>
  );
}

export default Blog;
