import { Routes, Route } from "react-router-dom";
import About from "../pages/about";
import Carousel from "../pages/carousel";
import Service from "../pages/service";
import Contact from "../pages/contact";
import Blog from "../pages/blog";
import Login from "../pages/login";
import StudentHome from "../pages/student/studentHome";
import StudentDashboard from "../pages/student/studentDashboard";
import StudentClub from "../pages/student/studentClub";
import StudentClubAdd from "../pages/student/studentClubAdd";
import StudentClubEdit from "../pages/student/studentClubEdit";
import StudentClubRead from "../pages/student/studentClubRead";
import StudentPost from "../pages/student/studentPost";
import StudentPostAdd from "../pages/student/studentPostAdd";
import StudentPostEdit from "../pages/student/studentPostEdit";
import StudentPostRead from "../pages/student/studentPostRead";
import StudentProduct from "../pages/student/studentProduct";
import StudentProductAdd from "../pages/student/studentProductAdd";
import StudentProductEdit from "../pages/student/studentProductEdit";
import StudentProductRead from "../pages/student/studentProductRead";
import StudentStats from "../pages/student/studentStats";
import StudentOrders from "../pages/student/studentOrders";
import StudentBuying from "../pages/student/studentBuying";
import StudentChat from "../pages/student/studentChat";
import StudentCart from "../pages/student/studentCart";
import StudentProfile from "../pages/student/studentProfile";
import StudentProfileEdit from "../pages/student/studentProfileEdit";
import BusinessOwnerHome from "../pages/businessowner/businessOwnerHome";
import BusinessChat from "../pages/businessowner/chat";
import BusinessDashboard from "../pages/businessowner/businessDashboard";
import BusinessProduct from "../pages/businessowner/businessProduct";
import BusinessProductAdd from "../pages/businessowner/businessProductAdd";
import BusinessProductEdit from "../pages/businessowner/businessProductEdit";
import BusinessProductRead from "../pages/businessowner/businessProductRead";
import BusinessOwnerProfile from "../pages/businessowner/businessownerprofile";
import BusinessOwnerProfileEdit from "../pages/businessowner/businessownerprofileedit";
import BusinessOrders from "../pages/businessowner/businessOrders";
import BusinessOrdersSelling from "../pages/businessowner/businessOrdersSelling";
import BusinessOrdersReturn from "../pages/businessowner/businessOrdersReturn";
import BusinessAd from "../pages/businessowner/businessAd";
import BusinessAdEdit from "../pages/businessowner/businessAdEdit";
import BusinessAdPost from "../pages/businessowner/businessAdPost";
import BusinessAdRead from "../pages/businessowner/businessAdRead";
import SchoolAdminHome from "../pages/schooladmin/schoolAdminHome";
import BusinessOwnerAdd from "../pages/schooladmin/businessOwnerAdd";
import SchoolAdminBO from "../pages/schooladmin/schoolAdminBO";
import SchoolAdminBOEdit from "../pages/schooladmin/schoolAdminBOEdit";
import SchoolAdminBORead from "../pages/schooladmin/schoolAdminBORead";
import SchoolAdminClubs from "../pages/schooladmin/schoolAdminClubs";
import SchoolAdminClubRead from "../pages/schooladmin/schoolAdminClubRead";
import SchoolAdminClubsEdit from "../pages/schooladmin/schoolAdminClubsEdit";
import SchoolAdminClubsAdd from "../pages/schooladmin/schoolAdminClubsAdd";
import SchoolAdminDashboard from "../pages/schooladmin/schoolAdminDashboard";
import SchoolAdminMP from "../pages/schooladmin/schoolAdminMP";
import SchoolAdminPost from "../pages/schooladmin/schoolAdminPost";
import SchoolAdminPostAdd from "../pages/schooladmin/schoolAdminPostAdd";
import SchoolAdminPostEdit from "../pages/schooladmin/schoolAdminPostEdit";
import SchoolAdminPostRead from "../pages/schooladmin/schoolAdminPostRead";
import SchoolAdminProfile from "../pages/schooladmin/schoolAdminProfile";
import SchoolAdminProfileEdit from "../pages/schooladmin/schoolAdminProfileEdit";
import SchoolAdminStudent from "../pages/schooladmin/schoolAdminStudent";
import SchoolAdminStudentEdit from "../pages/schooladmin/schoolAdminStudentEdit";
import SchoolAdminStudents from "../pages/schooladmin/schoolAdminStudents";
import SchoolStudentRead from "../pages/schooladmin/schoolStudentRead";
import StudentAdd from "../pages/schooladmin/studentAdd";
import StudentEdit from "../pages/schooladmin/studentEdit";
import ForgotPassword from "../pages/forgotpassword";
import Signup from "../pages/signup";
import SuperAdminHome from "../pages/superadmin/superAdminHome";
import SuperAdminDashboard from "../pages/superadmin/superAdminDashboard";
import SuperAdminBO from "../pages/superadmin/superAdminBO";
import SuperAdminBusinessOwnerAdd from "../pages/superadmin/superAdminBOAdd";
import SuperAdminBusinessOwnerEdit from "../pages/superadmin/superAdminBOEdit";
import SuperAdminBusinessOwnerRead from "../pages/superadmin/superAdminBORead";
import SuperAdminSchools from "../pages/superadmin/superAdminSchools";
import SuperAdminSchoolAdd from "../pages/superadmin/superAdminSchoolAdd";
import SuperAdminSchoolEdit from "../pages/superadmin/superAdminSchoolEdit";
import SuperAdminSchoolRead from "../pages/superadmin/superAdminSchoolRead";
import SuperSchoolAdmin from "../pages/superadmin/superSchoolAdmin";
import SuperSchoolAdminAdd from "../pages/superadmin/superSchoolAdminAdd";
import SuperSchoolAdminEdit from "../pages/superadmin/superSchoolAdminEdit";
import SuperSchoolAdminRead from "../pages/superadmin/superSchoolAdminRead";
import SuperAdminStudents from "../pages/superadmin/superAdminStudents";
import SuperAdminStudentAdd from "../pages/superadmin/superAdminStudentAdd";
import SuperAdminStudentEdit from "../pages/superadmin/superAdminStudentEdit";
import SuperAdminStudentRead from "../pages/superadmin/superAdminStudentRead";
import SuperAdminChats from "../pages/superadmin/superAdminChats";
import SuperAdminContactQueries from "../pages/superadmin/superAdminContactQueries";
import SuperAdminProfile from "../pages/superadmin/superAdminProfile";
import SuperAdminProfileEdit from "../pages/superadmin/superAdminProfileEdit";
import StudentDashboardRead from "../pages/student/studentDashboardRead";
import Cart from "../pages/cart/cart";
import Chat from "../pages/Chat/Chat";
import PostRead from "../pages/Posts/PostRead";
import AddUser from "../pages/User/AddUser";
import School from "../pages/School/School";
import SchoolRead from "../pages/School/SchoolRead";
import SchoolAdd from "../pages/School/SchoolAdd";
import SchoolEdit from "../pages/School/SchoolEdit";
import EditUser from "../pages/User/EditUser";
import OrderSummary from "../pages/Orders/OrderSummary";
import SellingOrders from "../pages/Orders/SellingOrder";
import BuyingOrders from "../pages/Orders/BuyingOrders";
import Ads from "../pages/Ads/Ads";
import AdsRead from "../pages/Ads/AdsRead";

function Router() {
  return (
    <Routes>
      <Route path="studenthome" element={<StudentHome />}>
        <Route path="dashboard" element={<StudentDashboard />} />
        <Route path="studentclub" element={<StudentClub />} />
        <Route path="studentclub/add" element={<StudentClubAdd />} />
        <Route path="studentclub/edit" element={<StudentClubEdit />} />
        <Route path="studentclub/read" element={<StudentClubRead />} />
        <Route path="studentposts" element={<StudentPost />} />
        <Route
          path="/studenthome/studentposts/add"
          element={<StudentPostAdd />}
        />
        <Route
          path="/studenthome/studentposts/edit"
          element={<StudentPostEdit />}
        />
        <Route path="/studenthome/studentposts/read" element={<PostRead />} />
        <Route path="studentproducts" element={<StudentProduct />} />
        <Route
          path="studentproducts/studentproductsadd"
          element={<StudentProductAdd />}
        />
        <Route
          path="studentproducts/studentproductsedit"
          element={<StudentProductEdit />}
        />
        <Route
          path="studentproducts/studentproductsread"
          element={<StudentProductRead />}
        />
        <Route path="studentstats" element={<OrderSummary />} />
        <Route path="studentstats/sellingorders" element={<SellingOrders />} />
        <Route path="studentstats/buyingorders" element={<BuyingOrders />} />
        <Route path="studentchats" element={<Chat />} />
        <Route path="dashboard/studentcart" element={<Cart />} />
        <Route path="studentprofile" element={<StudentProfile />} />
        <Route
          path="studentprofile/studentprofileedit"
          element={<StudentProfileEdit />}
        />
        <Route path="dashboard/studentdashboardread" element={<PostRead />} />
      </Route>
      <Route path="businessownerhome" element={<BusinessOwnerHome />}>
        <Route path="chats" element={<Chat />} />
        <Route path="dashboard" element={<BusinessDashboard />} />
        <Route path="products" element={<BusinessProduct />} />
        <Route
          path="products/businessproductsadd"
          element={<BusinessProductAdd />}
        />
        <Route
          path="products/businessproductsedit"
          element={<BusinessProductEdit />}
        />
        <Route
          path="products/businessproductsread"
          element={<BusinessProductRead />}
        />
        <Route path="profile" element={<BusinessOwnerProfile />} />
        <Route
          path="profile/businessprofileedit"
          element={<BusinessOwnerProfileEdit />}
        />
        <Route path="orders" element={<OrderSummary />} />
        <Route path="orders/sellingorders" element={<SellingOrders />} />
        <Route path="orders/buyingorders" element={<BuyingOrders />} />
        <Route path="orders/return" element={<BusinessOrdersReturn />} />
        <Route path="ads" element={<Ads />} />
        <Route path="ads/edit" element={<BusinessAdEdit />} />
        <Route path="ads/post" element={<BusinessAdPost />} />
        <Route path="ads/read" element={<AdsRead />} />
      </Route>
      <Route path="schooladminhome" element={<SchoolAdminHome />}>
        <Route path="businessowner/add" element={<AddUser />} />
        <Route path="businessowner" element={<SchoolAdminBO />} />
        <Route path="businessowner/edit" element={<EditUser />} />
        <Route path="businessowner/read" element={<SchoolAdminBORead />} />
        <Route path="clubs" element={<SchoolAdminClubs />} />
        <Route path="clubs/read" element={<SchoolAdminClubRead />} />
        <Route path="clubs/edit" element={<SchoolAdminClubsEdit />} />
        <Route path="clubs/add" element={<SchoolAdminClubsAdd />} />
        <Route path="dashboard" element={<SchoolAdminDashboard />} />
        <Route path="mp" element={<SchoolAdminMP />} />
        <Route path="posts" element={<SchoolAdminPost />} />
        <Route path="posts/add" element={<SchoolAdminPostAdd />} />
        <Route path="posts/edit" element={<SchoolAdminPostEdit />} />
        <Route path="posts/read" element={<PostRead />} />
        <Route path="profile" element={<SchoolAdminProfile />} />
        <Route path="profile/edit" element={<SchoolAdminProfileEdit />} />
        <Route path="student" element={<SchoolAdminStudent />} />
        <Route path="student/edit" element={<SchoolAdminStudentEdit />} />
        <Route path="students" element={<SchoolAdminStudents />} />
        <Route path="students/read" element={<SchoolStudentRead />} />
        <Route path="students/add" element={<AddUser />} />
        <Route path="students/edit" element={<StudentEdit />} />
      </Route>
      <Route path="superadminhome" element={<SuperAdminHome />}>
        <Route path="dashboard" element={<SuperAdminDashboard />} />
        <Route path="businessowners" element={<SuperAdminBO />} />
        <Route path="businessowners/add" element={<AddUser />} />
        <Route path="businessowners/edit" element={<EditUser />} />
        <Route
          path="businessowners/read"
          element={<SuperAdminBusinessOwnerRead />}
        />
        <Route path="schools" element={<School />} />
        <Route path="schools/add" element={<SchoolAdd />} />
        <Route path="schools/edit" element={<SchoolEdit />} />
        <Route path="schools/read" element={<SchoolRead />} />
        <Route path="schooladmins" element={<SuperSchoolAdmin />} />
        <Route path="schooladmins/add" element={<AddUser />} />
        <Route path="schooladmins/edit" element={<EditUser />} />
        <Route path="schooladmins/read" element={<SuperSchoolAdminRead />} />
        <Route path="students" element={<SuperAdminStudents />} />
        <Route path="students/add" element={<AddUser />} />
        <Route path="students/edit" element={<EditUser />} />
        <Route path="students/read" element={<SuperAdminStudentRead />} />
        <Route path="chats" element={<Chat />} />
        <Route path="queries" element={<SuperAdminContactQueries />} />
        <Route path="profile" element={<SuperAdminProfile />} />
        <Route path="profile/edit" element={<SuperAdminProfileEdit />} />
      </Route>
      <Route path="/" element={<Carousel />} />
      <Route path="login" element={<Login />} />
      <Route path="about" element={<About />} />
      <Route path="blog" element={<Blog />} />
      <Route path="forgotpassword" element={<ForgotPassword />} />
      <Route path="service" element={<Service />} />
      <Route path="contact" element={<Contact />} />
      <Route path="signup" element={<Signup />} />
    </Routes>
  );
}

export default Router;
