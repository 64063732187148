/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createOrUpdateClub } from "../../API/clubs";
import { createOrUpdatePosts } from "../../API/posts";
import camera from "../../assets/club1.png";
import ErrorMessage from "../../components/ErrorMessage";
import { useAuth } from "../../hooks/auth";

function SchoolAdminClubsEdit() {
  const location = useLocation();
  const { club } = location.state;
  console.log(club);
  const initialState = {
    name: club?.name || "",
    description: club?.description || "",
    id: club?.id,
  };
  const errorState = {
    name: "",
    description: "",
  };
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState(errorState);
  const auth = useAuth();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };
  const isValidated = () => {
    let tempErrors = {};
    //validation
    if (!data?.name?.trim()) {
      tempErrors.name = "Name is a required field";
    }
    if (!data?.description?.trim()) {
      tempErrors.description = "Description is a required field";
    }

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0 ? true : false;
  };
  const handleCreateOrUpdateClubs = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    const tempData = {
      club_id: data.id || -1,
      club_name: data.name,
      club_description: data.description,
      created_by: data.owner_id || user?.id,
      is_deleted: data.delete || 0,
    };
    try {
      const res = await createOrUpdateClub(tempData);
      if (
        res.reponse === "success" ||
        res.reponse === "SUCCESS" ||
        res.response == "success" ||
        res.resposne == "SUCCESS" ||
        res.response == "SUCCESS"
      ) {
        toast.success("Updated successfully");
        navigate("/schooladminhome/clubs/");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">Club</h1>
        <Link
          className="primary_btn"
          type="button"
          to="/schooladminhome/clubs/"
        >
          back
        </Link>
      </div>
      <div className="products-area-wrapper tableView">
        <div className="products-header">
          <div className="products-row1">
            <section>
              <div className="student">
                <section>
                  <div className="product">
                    <form
                      onSubmit={handleCreateOrUpdateClubs}
                      className="product_form"
                    >
                      <input
                        name="name"
                        value={data.name}
                        onChange={handleInputChange}
                        type="text"
                        id="product-name"
                        className="input_box form_input"
                        placeholder="Ad name"
                      />
                      {errors.name && <ErrorMessage message={errors.name} />}
                      <textarea
                        name="description"
                        value={data.description}
                        onChange={handleInputChange}
                        id="des"
                        className="input_box form_input"
                        placeholder="detail description"
                        rows={6}
                      />
                      {errors.description && (
                        <ErrorMessage message={errors.description} />
                      )}
                      {/* product image */}
                      <div className="product-info">
                        <div className="product-image">
                          <img src={camera} alt="" />
                        </div>
                        <div
                          className="upload-image-sec"
                          style={{ background: "none" }}
                        >
                          {/* upload inputs */}
                          <div>
                            <p className="text">
                              <img src={""} alt="" />
                              upload image
                            </p>
                          </div>
                          <div>
                            <div
                              className="upload-catalouge edit_upload-catalouge"
                              style={{
                                background: "#fff",
                                padding: "20px",
                                borderRadius: "20px",
                                width: "40%",
                              }}
                            >
                              <input
                                type="file"
                                className="fileupload"
                                id="first-file-upload-btn"
                                hidden
                              />
                              <label
                                htmlFor="first-file-upload-btn"
                                className="upload-image"
                              >
                                +
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="buttons update_btn">
                        <button
                          type="submit"
                          className="primary_btn"
                          id="add-btn"
                        >
                          update
                        </button>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolAdminClubsEdit;
