
import React from 'react';
import { NavLink } from 'react-router-dom';
import '../CSS/mobileSideNav.css'
import owner from '../assets/owner.png'

function NavBarBO() {
    const [click, setClick] = React.useState(false);

    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);

    return (
        <div>
            <div className={click ? "main-container" : ""} onClick={() => Close()} />
            <nav className="navbarspc" onClick={e => e.stopPropagation()}>
                <div className="nav-container">
                    <div className="logo_header">
                        <img src={owner} style={{ height: '80px' }} />

                        <div className="nav-icon" onClick={handleClick}>
                            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
                        </div>
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="dashboard"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="ads"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                Ads
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="products"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                Products
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="orders"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                Orders
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="chats"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                Chat
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="profile"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                Profile
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default NavBarBO;