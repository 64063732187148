/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createOrUpdateAds } from "../../API/ads";
import ErrorMessage from "../../components/ErrorMessage";
import { useAuth } from "../../hooks/auth";

function BusinessAdPost() {
  const initialState = {
    name: "",
    description: "",
  };
  const errorState = {
    name: "",
    description: "",
  };
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState(errorState);
  const auth = useAuth();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };
  const isValidated = () => {
    let tempErrors = {};
    //validation
    if (!data?.name?.trim()) {
      tempErrors.name = "Name is a required field";
    }
    if (!data?.description?.trim()) {
      tempErrors.description = "Description is a required field";
    }

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0 ? true : false;
  };
  const handleCreateOrUpdateAds = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    const tempData = {
      ad_id: data.id || -1,
      ad_name: data.name,
      ad_description: data.description,
      created_by: data.owner_id || user?.id,
      is_deleted: data.delete || 0,
    };
    try {
      const res = await createOrUpdateAds(tempData);
      if (
        res.reponse === "success" ||
        res.reponse === "SUCCESS" ||
        res.response == "success" ||
        res.resposne == "SUCCESS"
      ) {
        toast.success("Added successfully");
        navigate("/businessownerhome/ads/");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">Ad</h1>
        <Link className="primary_btn" type="button" to="/businessownerhome/ads">
          back
        </Link>
      </div>
      <div className="products-area-wrapper tableView">
        <div className="products-header">
          <div className="products-row1">
            <section>
              <div className="student1">
                <section>
                  <div className="product">
                    <form
                      onSubmit={handleCreateOrUpdateAds}
                      className="product_form"
                    >
                      <input
                        name="name"
                        value={data.name}
                        onChange={handleInputChange}
                        type="text"
                        id="product-name"
                        className="input_box form_input"
                        placeholder="ad name"
                      />
                      {errors.name && <ErrorMessage message={errors.name} />}
                      <textarea
                        name="description"
                        value={data.description}
                        onChange={handleInputChange}
                        id="des"
                        className="input_box form_input"
                        placeholder="ad detail description"
                      />
                      {errors.description && (
                        <ErrorMessage message={errors.description} />
                      )}
                      {/* product image */}
                      <div className="product-info">
                        {/* <div class="product-image"><p class="text">product image</p></div> */}
                        <div className="upload-image-sec">
                          {/* upload inputs */}
                          <p className="text">
                            <img src={""} alt="" />
                            upload image
                          </p>
                          <div className="upload-catalouge">
                            <input
                              type="file"
                              className="fileupload"
                              id="first-file-upload-btn"
                              hidden
                            />
                            <label
                              htmlFor="first-file-upload-btn"
                              className="upload-image"
                            >
                              +
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="buttons">
                        <button
                          type="submit"
                          className="primary_btn"
                          id="add-btn"
                        >
                          add ad
                        </button>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessAdPost;
