import { BASE_URL_API } from "./base";

export const getAllAds = async (data) => {
  const tempData = {
    user_id: data.id || "-1",
    school_id: data.school || "-1",
  };
  const rawResponse = await fetch(`${BASE_URL_API}/getads.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();

  return content;
};
export const createOrUpdateAds = async (data) => {
  const rawResponse = await fetch(`${BASE_URL_API}/createorupdatead.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const content = await rawResponse.json();

  return content;
};
