import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createOrUpdateSchools } from "../../API/schools";
import ErrorMessage from "../../components/ErrorMessage";

function SchoolAdd() {
  const initialState = {
    name: "",
    phone: "",
    address: "",
  };
  const [errors, setErrors] = useState(initialState);
  const navigate = useNavigate();
  const [data, setData] = useState(initialState);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };
  const PHONE_REGEX =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const isValidated = () => {
    let tempErrors = {};
    //validation
    if (!data?.name?.trim()) {
      tempErrors.name = "Name is a required field";
    }
    if (!data?.phone?.trim()) {
      tempErrors.phone = "Phone number is a required field";
    } else if (!PHONE_REGEX.test(data.phone)) {
      tempErrors.phone = "Enter a valid phone number";
    }
    if (!data?.address?.trim()) {
      tempErrors.address = "Address is a required field";
    }

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0 ? true : false;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    try {
      const res = await createOrUpdateSchools(data);
      if (res.response === "SUCCESS") {
        toast.success(res.message || res.Message);
        navigate(-1);
      } else if (res.response === "FAILURE") {
        toast.error(res.Message || "Something went wrong");
        setData(initialState);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setData(initialState);
    }
  };
  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">Schools</h1>
        <Link
          type="button"
          to="/superadminhome/schools"
          className="primary_btn"
        >
          Back
        </Link>
      </div>
      <div className="products-area-wrapper tableView">
        <section>
          <div className="signup_wrapper">
            <div className="signup">
              <form onSubmit={handleSubmit}>
                <h1>Add School</h1>
                <div className="login_container">
                  <label htmlFor>School Name</label>
                  <input
                    type="text"
                    name="name"
                    value={data.name}
                    onChange={handleInputChange}
                    id="name"
                    className="input_box signup_input"
                    placeholder="School Name"
                  />
                  {errors.name && <ErrorMessage message={errors.name} />}
                  <label htmlFor>School Phone</label>
                  <input
                    type="number"
                    name="phone"
                    value={data.phone}
                    onChange={handleInputChange}
                    id="phone"
                    className="input_box signup_input"
                    placeholder="Mobile Number"
                  />
                  {errors.phone && <ErrorMessage message={errors.phone} />}

                  <label htmlFor="address">School Address</label>
                  <textarea
                    className="input_box signup_input"
                    name="address"
                    value={data.address}
                    onChange={handleInputChange}
                    id="address"
                    rows={4}
                    cols={50}
                    defaultValue={""}
                  />
                  {errors.address && <ErrorMessage message={errors.address} />}

                  <button className="primary_btn" id="submit" value="submit">
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SchoolAdd;
