/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { Link } from 'react-router-dom';
import iphone from '../../assets/iphone.png'

function StudentDashboardRead() {
    return (
        <div className="app-content">
            <div className="app-content-header" style={{ padding: '20px 0' }}>
                <h1 className="app-content-headerText">Post</h1>
                <Link className="primary_btn" type="button" to="/studenthome/dashboard">back</Link>
            </div>
            <div className="products-area-wrapper tableView">
                <div className="products-header">
                    <div className="products-row1">
                        <section>
                            <div className="student" style={{ backgroundColor: '#fff', flexDirection: 'column', display: 'flex' }}>
                                <div className="read_post">
                                    <img src={iphone} />
                                    <h1 style={{ padding: '20px 0', fontSize: '40px' }}>Post 1</h1>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod, optio minus dolorem corrupti ut provident ab doloremque nostrum fuga libero corporis nam distinctio culpa consectetur voluptatibus blanditiis sed dolores consequatur.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default StudentDashboardRead;
