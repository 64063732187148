import { BASE_URL_API } from "./base";

export const createOrUpdateUser = async (data) => {
  const tempData = {
    user_id: data.id || -1,
    user_name: data.name,
    email_id: data.email,
    phone_number: data.phone,
    address: data.address,
    role_id: data.role,
    password: data.password,
    school_id: data.school || 1,
    is_deleted: data.deleted || 0,
  };
  const rawResponse = await fetch(
    `${BASE_URL_API}/createorupdateuserdetails.php`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tempData),
    }
  );
  const content = await rawResponse.json();

  return content;
};
