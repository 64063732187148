import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createOrUpdateUser, getAllSchools } from "../API";
import ErrorMessage from "../components/ErrorMessage";
import Footer from "../components/footer";
import Header from "../components/header";
import Loader from "../components/Loader";
import projs from "../Js/pro";

function Signup() {
  const initialState = {
    name: "",
    email: "",
    phone: "",
    address: "",
    role: "",
    password: "",
    cpassword: "",
    school: "",
  };
  const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const PHONE_REGEX =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const [errors, setErrors] = useState(initialState);
  const [data, setData] = useState(initialState);
  const [schools, setSchools] = useState(null);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };
  const getSchools = async () => {
    try {
      const res = await getAllSchools({});
      if (res.reponse === "success") {
        setSchools(res.data);
      } else {
        toast.error("Failed to load page");
        navigate("/");
      }
    } catch (error) {
      toast.error("Failed to load page");
      navigate("/");
    }
  };
  useEffect(() => {
    getSchools();
  }, []);
  const isValidated = () => {
    let tempErrors = {};
    //validation
    if (!data?.name?.trim()) {
      tempErrors.name = "Name is a required field";
    }
    if (!data?.email?.trim()) {
      tempErrors.email = "Email is a required field";
    } else if (!EMAIL_REGEX.test(data.email)) {
      tempErrors.email = "Enter a valid email";
    }
    if (!data?.phone?.trim()) {
      tempErrors.phone = "Phone number is a required field";
    } else if (!PHONE_REGEX.test(data.phone)) {
      tempErrors.phone = "Enter a valid phone number";
    }
    if (!data?.address?.trim()) {
      tempErrors.address = "Address is a required field";
    }
    if (!data?.password?.trim()) {
      tempErrors.password = "Password is a required field";
    }
    if (!data?.cpassword) {
      tempErrors.cpassword = "Confirm password is a required field";
    } else if (data.password !== data.cpassword) {
      tempErrors.cpassword = "Passwords should match";
    }
    if (!data?.role) {
      tempErrors.role = "Please select a role";
    }
    if (!data?.school) {
      tempErrors.school = "Please select a school";
    }

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0 ? true : false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    try {
      const res = await createOrUpdateUser(data);
      if (res.response === "SUCCESS") {
        toast.success("User created successfully");
        navigate("/login");
      } else if (res.response === "FAILURE" || res.response == "failure") {
        toast.error(res.Message || "Something went wrong");
        setData(initialState);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setData(initialState);
    }
  };
  return (
    <div>
      <script
        src="https://kit.fontawesome.com/82f797382f.js"
        crossOrigin="anonymous"
      ></script>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <link rel="stylesheet" href="owlcarousel/owl.carousel.min.css"></link>
      <script src={projs} defer></script>
      <Header />
      {schools ? (
        <>
          <section>
            <div className="signup_wrapper">
              <div className="signup">
                <form onSubmit={handleSubmit}>
                  <h1>Signup</h1>
                  <div className="login_container">
                    <label htmlFor="name">Name</label>
                    <input
                      value={data?.name}
                      onChange={handleInputChange}
                      type="text"
                      name="name"
                      id="name"
                      className="input_box signup_input"
                      placeholder="Username"
                    />
                    {errors.name && <ErrorMessage message={errors.name} />}
                    <label htmlFor="email">Email</label>
                    <input
                      value={data?.email}
                      onChange={handleInputChange}
                      type="email"
                      name="email"
                      id="email"
                      className="input_box signup_input"
                      placeholder="Email"
                    />
                    {errors.email && <ErrorMessage message={errors.email} />}

                    <label htmlFor>Phone</label>
                    <input
                      value={data?.phone}
                      onChange={handleInputChange}
                      type="number"
                      name="phone"
                      id="phone"
                      className="input_box signup_input"
                      placeholder="Mobile Number"
                    />
                    {errors.phone && <ErrorMessage message={errors.phone} />}

                    <label htmlFor="address">Address</label>
                    <textarea
                      value={data?.address}
                      className="input_box signup_input"
                      name="address"
                      id="address"
                      rows={4}
                      cols={50}
                      defaultValue={""}
                      onChange={handleInputChange}
                    />
                    {errors.address && (
                      <ErrorMessage message={errors.address} />
                    )}

                    <label htmlFor="role">Role</label>
                    <select
                      value={data?.role}
                      name="role"
                      id="role"
                      onChange={handleInputChange}
                      className="input_box signup_dd"
                    >
                      <option value="">Select Role</option>
                      <option value="1">Business Owner</option>
                      <option value="2">School Admin</option>
                      <option value="3">Student</option>
                    </select>
                    {errors.role && <ErrorMessage message={errors.role} />}

                    <label htmlFor="school">School</label>
                    <select
                      value={data?.school}
                      name="school"
                      id="school"
                      onChange={handleInputChange}
                      className="input_box signup_dd"
                    >
                      <option value="">Select School</option>
                      {schools.map((el) => (
                        <option value={el.School_ID}>{el?.School_Name}</option>
                      ))}
                    </select>
                    {errors.school && <ErrorMessage message={errors.school} />}
                    <label htmlFor="password">Password</label>
                    <input
                      value={data?.password}
                      type="password"
                      name="password"
                      id="password"
                      className="input_box signup_input"
                      placeholder="Password"
                      onChange={handleInputChange}
                    />
                    {errors.password && (
                      <ErrorMessage message={errors.password} />
                    )}

                    <label htmlFor="cpassword">Confirm Password</label>
                    <input
                      value={data?.cpassword}
                      type="password"
                      name="cpassword"
                      id="cpassword"
                      className="input_box signup_input"
                      placeholder="Confirm Password"
                      onChange={handleInputChange}
                    />
                    {errors.cpassword && (
                      <ErrorMessage message={errors.cpassword} />
                    )}
                    <button
                      type="submit"
                      class="primary_btn"
                      id="submit"
                      value="submit"
                    >
                      SignUp
                    </button>
                    {/* <a
                href="mailto:vobbiliredy.anu6298@gmail.com?Subject=You are about to signup&body=Your email is abc@gmail and password for login is 123456 "
                className="primary_btn"
              >
                Signup
              </a> */}
                    <span>
                      Already have an account
                      <Link style={{ marginLeft: "5px" }} to="/login">
                        Login
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Signup;
