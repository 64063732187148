import { Link, useLocation } from "react-router-dom";

function SuperAdminBusinessOwnerRead() {
  const location = useLocation();
  const { data } = location.state;
  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">Business Owner</h1>
        <Link
          className="primary_btn"
          type="button"
          to="/superadminhome/businessowners"
        >
          back
        </Link>
      </div>
      <div className="products-area-wrapper tableView">
        <div className="main">
          {/* <h2>IDENTITY</h2> */}
          <div className="card">
            <div className="card-body">
              {/* <i class="fa fa-pen fa-xs edit"></i> */}
              <table style={{ borderCollapse: "none", border: "none" }}>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>:</td>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td>{data.email}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>:</td>
                    <td>{data.address}</td>
                  </tr>
                  <tr>
                    <td>Password</td>
                    <td>:</td>
                    <td>**********</td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td>:</td>
                    <td> Business Owner</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>:</td>
                    <td>{data.phone_number}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperAdminBusinessOwnerRead;
