/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createOrUpdateProduct } from "../../API/product";
import club from "../../assets/club1.png";
import ErrorMessage from "../../components/ErrorMessage";
import { useAuth } from "../../hooks/auth";

function StudentProductEdit() {
  const location = useLocation();
  const { product } = location.state;
  const initialState = {
    productName: product.product_name,
    shortDesc: product.short_desc,
    description: product.description,
    price: product.price,
    stockCount: product.stock_count,
    id: product.id,
  };
  const errorState = {
    productName: "",
    shortDesc: "",
    description: "",
    price: "",
    stockCount: "",
  };
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState(errorState);
  const auth = useAuth();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };
  const isValidated = () => {
    let tempErrors = {};
    //validation
    if (!data?.productName?.trim()) {
      tempErrors.productName = "Name is a required field";
    }
    if (!data?.description?.trim()) {
      tempErrors.description = "Description is a required field";
    }
    if (!data?.price?.trim()) {
      tempErrors.price = "Price is a required field";
    }
    if (!data?.shortDesc?.trim()) {
      tempErrors.shortDesc = "Short note is a required field";
    }
    if (!data?.stockCount?.trim()) {
      tempErrors.stockCount = "stock count is a required field";
    }

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0 ? true : false;
  };
  const handleCreateOrUpdateProducts = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    const tempData = {
      product_id: data.id || -1,
      product_name: data.productName,
      short_desc: data.shortDesc,
      description: data.description,
      price: data.price,
      stock_count: data.stockCount,
      created_by: user?.id,
      is_deleted: data.deleted || 0,
    };
    try {
      const res = await createOrUpdateProduct(tempData);
      if (
        res.reponse === "success" ||
        res.reponse === "SUCCESS" ||
        res.response == "success" ||
        res.resposne == "SUCCESS" ||
        res.resposne == "success"
      ) {
        toast.success("Updated successfully");
        navigate("/studenthome/studentproducts/");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">Edit Product</h1>
        <Link
          type="button"
          to="/studenthome/studentproducts"
          className="primary_btn"
        >
          back
        </Link>
      </div>
      <div className="products-area-wrapper tableView">
        <div className="products-header">
          <div className="products-row1">
            <section>
              <div className="product">
                <form
                  onSubmit={handleCreateOrUpdateProducts}
                  className="product_form"
                >
                  <input
                    name="productName"
                    value={data.productName}
                    onChange={handleInputChange}
                    type="text"
                    id="product-name"
                    className="input_box form_input"
                    placeholder="product name"
                  />
                  {errors.productName && (
                    <ErrorMessage message={errors.productName} />
                  )}
                  <input
                    name="shortDesc"
                    value={data.shortDesc}
                    onChange={handleInputChange}
                    type="text"
                    id="short-des"
                    className="input_box form_input"
                    placeholder="short line about the product"
                  />
                  {errors.shortDesc && (
                    <ErrorMessage message={errors.shortDesc} />
                  )}
                  <textarea
                    name="description"
                    value={data.description}
                    onChange={handleInputChange}
                    id="des"
                    className="input_box form_input"
                    placeholder="detail description"
                    rows={6}
                  />
                  {errors.description && (
                    <ErrorMessage message={errors.description} />
                  )}
                  {/* product image */}
                  <div className="product-info">
                    <div className="product-image">
                      <img src={club} alt="" />
                    </div>
                    <div
                      className="upload-image-sec"
                      style={{ background: "none" }}
                    >
                      {/* upload inputs */}
                      <div>
                        <p className="text">
                          <img src="img/camera.png" alt="" />
                          upload image
                        </p>
                      </div>
                      <div>
                        <div
                          className="upload-catalouge edit_upload-catalouge"
                          style={{
                            background: "#fff",
                            padding: "20px",
                            borderRadius: "20px",
                            width: "40%",
                          }}
                        >
                          <input
                            type="file"
                            className="fileupload"
                            id="first-file-upload-btn"
                            hidden
                          />
                          <label
                            htmlFor="first-file-upload-btn"
                            className="upload-image"
                          >
                            +
                          </label>
                        </div>
                        <div className="product-price edit_product_price">
                          <input
                            name="price"
                            value={data.price}
                            onChange={handleInputChange}
                            type="number"
                            id="actual-price"
                            className="input_box form_input"
                            placeholder="actual price"
                          />
                          {errors.price && (
                            <ErrorMessage message={errors.price} />
                          )}
                          <input
                            name="stockCount"
                            value={data.stockCount}
                            onChange={handleInputChange}
                            type="number"
                            className="input_box form_input"
                            id="stock"
                            min={20}
                            placeholder="item in stocks (minimum 20)"
                          />
                          {errors.stockCount && (
                            <ErrorMessage message={errors.stockCount} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="buttons update_btn">
                    <button type="submit" className="primary_btn" id="add-btn">
                      update product
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentProductEdit;
