/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";
import { getRole } from "../../utils/utils";
import { useAuth } from "../../hooks/auth";
import { useEffect, useState } from "react";

function StudentProfile() {
  const auth = useAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);

  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">Student Profile</h1>
        <Link className="primary_btn" type="button" to="/studenthome/dashboard">
          back
        </Link>
      </div>
      <div className="products-area-wrapper tableView">
        <div className="products-header">
          <div className="products-row1">
            <section>
              <div className="profile_content" style={{ marginTop: "50px" }}>
                {/* Sidenav */}
                <div className="sidenav">
                  <div className="profile">
                    <img
                      src="https://imdezcode.files.wordpress.com/2020/02/imdezcode-logo.png"
                      alt=""
                      width={100}
                      height={100}
                    />
                    <div className="name">{}</div>
                    <div className="job">Student</div>
                  </div>
                  <div className="sidenav-url">
                    <div className="url">
                      <Link to="studentprofileedit" className="active">
                        Edit Profile
                      </Link>
                      <hr align="center" />
                    </div>
                    <div className="url">
                      <Link
                        onClick={window.localStorage.setItem(
                          "cart",
                          JSON.stringify([])
                        )}
                        to="/"
                      >
                        Logout
                      </Link>
                      <hr align="center" />
                    </div>
                  </div>
                </div>
                {/* End */}
                {/* Main */}
                <div className="main">
                  <h2>IDENTITY</h2>
                  <div className="card">
                    <div className="card-body">
                      {/* <i class="fa fa-pen fa-xs edit"></i> */}
                      <table
                        style={{
                          borderCollapse: "none",
                          border: "none",
                          paddingRight: "30px",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <td>:</td>
                            <td>{user?.name}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>:</td>
                            <td>{user?.email}</td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>:</td>
                            <td>{user?.address}</td>
                          </tr>
                          <tr>
                            <td>Password</td>
                            <td>:</td>
                            <td>**********</td>
                          </tr>
                          <tr>
                            <td>Role</td>
                            <td>:</td>
                            <td>{getRole(user?.role_id)}</td>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td>:</td>
                            <td>{user?.phone_number}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentProfile;
