import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllPosts } from "../../API";
import { createOrUpdatePosts } from "../../API/posts";
import Loader from "../../components/Loader";

function SchoolAdminPost() {
  const [posts, setPosts] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  const handleCreateOrUpdatePosts = async (data) => {
    const tempData = {
      post_id: data.id,
      post_name: data.name,
      post_description: data.description,
      created_by: data.owner_id || user?.id,
      is_deleted: data.delete || 1,
    };
    try {
      const res = await createOrUpdatePosts(tempData);
      if (
        res.reponse === "success" ||
        res.reponse === "SUCCESS" ||
        res.response == "success" ||
        res.resposne == "SUCCESS" ||
        res.response == "SUCCESS"
      ) {
        toast.success("Deleted successfully");
        getPosts();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const getPosts = async () => {
    try {
      const res = await getAllPosts({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setPosts(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };

  useEffect(() => {
    getPosts();
  }, []);
  return (
    <>
      {posts ? (
        <div className="app-content">
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            <h1 className="app-content-headerText">Post</h1>
            <Link className="primary_btn" type="button" to="add">
              add
            </Link>
          </div>
          <div className="products-area-wrapper tableView">
            <div className="products-header">
              <div className="products-row">
                <section>
                  <div className="student_post">
                    {posts.map((post) => (
                      <div className="service_card_wrapper">
                        <div className="pic" />
                        <p className="card_header">{post.name}</p>
                        <p className="card_content">
                          {post.description.length > 15
                            ? post.description.slice(0, 15 - 1) + "...."
                            : post.description}
                        </p>
                        {/* <div class="">
                              <a href="schoolAdminPostEdit.html">
                                <div class="card_readmore"><b></b> Edit <i class="fa fa-pencil" aria-hidden="true"></i></div>
                              </a>
                            </div> */}

                        {user?.id == post.created_by ? (
                          <>
                            <div className>
                              <Link state={{ post }} to="edit">
                                <div className="card_readmore">
                                  <b /> Edit{" "}
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  />
                                </div>
                              </Link>
                            </div>
                            <a
                              href="#"
                              onClick={() => handleCreateOrUpdatePosts(post)}
                            >
                              <div className="card_readmore">
                                <b>Delete</b>{" "}
                                <i className="fas fa-angle-right" />
                              </div>
                            </a>
                          </>
                        ) : (
                          <Link state={{ post }} to="read">
                            <div className="card_readmore">
                              <b>Read more</b>{" "}
                              <i className="fas fa-angle-right" />
                            </div>
                          </Link>
                        )}
                      </div>
                    ))}
                    {/* <div className="service_card_wrapper">
                      <div className="pic" />
                      <p className="card_header">Post 1</p>
                      <p className="card_content">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                      </p>
                      <div class="">
                        <a href="schoolAdminPostEdit.html">
                          <div class="card_readmore"><b></b> Edit <i class="fa fa-pencil" aria-hidden="true"></i></div>
                        </a>
                      </div>
                      <Link to="read">
                        <div className="card_readmore">
                          <b>Read more</b> <i className="fas fa-angle-right" />
                        </div>
                      </Link>
                    </div>
                    <div className="service_card_wrapper">
                      <div className="pic2" />
                      <p className="card_header">Post 2</p>
                      <p className="card_content">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                      </p>
                      <div className>
                        <Link to="edit">
                          <div className="card_readmore">
                            <b /> Edit{" "}
                            <i className="fa fa-pencil" aria-hidden="true" />
                          </div>
                        </Link>
                      </div>
                      <a href="#" onlick="alert('Do you want to delete?')">
                        <div className="card_readmore">
                          <b>Delete</b> <i className="fas fa-angle-right" />
                        </div>
                      </a>
                    </div>
                    <div className="service_card_wrapper">
                      <div className="pic3" />
                      <p className="card_header">Post 3</p>
                      <p className="card_content">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                      </p>
                      <div class="">
                        <a href="schoolAdminPostEdit.html">
                          <div class="card_readmore"><b></b> Edit <i class="fa fa-pencil" aria-hidden="true"></i></div>
                        </a>
                      </div>
                      <Link to="read">
                        <div className="card_readmore">
                          <b>Read more</b> <i className="fas fa-angle-right" />
                        </div>
                      </Link>
                    </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default SchoolAdminPost;
