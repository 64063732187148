import owner from "../../assets/owner.png";
import dummyProfilePic from "../../assets/dummy-profile-pic.png";
import "../../App.css";
import "../../CSS/dashboard.css";
import { Link, Outlet } from "react-router-dom";
import NavBarSuperAdmin from "../../components/navbarSuperAdmin";
import { useAuth } from "../../hooks/auth";
import { useEffect, useState } from "react";

function SuperAdminHome() {
  const auth = useAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  return (
    <div>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <link rel="stylesheet" href="../../CSS/global.css" />
      <link rel="stylesheet" href="../../CSS/dashboard.css" />
      <title>Super Admin Dashboard</title>
      <section>
        <NavBarSuperAdmin />
      </section>
      <section>
        <div className="app-container">
          <div className="sidebar">
            <div className="sidebar-header">
              <div className="app-icon">
                <Link to="/" className="logo">
                  <img src={owner} style={{ height: "80px" }} />
                </Link>
              </div>
            </div>
            <ul className="sidebar-list">
              <li className="sidebar-list-item">
                <Link to="dashboard">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-home"
                  >
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                    <polyline points="9 22 9 12 15 12 15 22" />
                  </svg>
                  <span>Home</span>
                </Link>
              </li>
              <li className="sidebar-list-item">
                <Link to="businessowners">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-shopping-bag"
                  >
                    <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
                    <line x1={3} y1={6} x2={21} y2={6} />
                    <path d="M16 10a4 4 0 0 1-8 0" />
                  </svg>
                  <span>Business Owners</span>
                </Link>
              </li>
              <li className="sidebar-list-item">
                <Link to="schools">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-pie-chart"
                  >
                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                    <path d="M22 12A10 10 0 0 0 12 2v10z" />
                  </svg>
                  <span>Schools</span>
                </Link>
              </li>
              <li className="sidebar-list-item">
                <Link to="schooladmins">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-pie-chart"
                  >
                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                    <path d="M22 12A10 10 0 0 0 12 2v10z" />
                  </svg>
                  <span>School Admins</span>
                </Link>
              </li>
              <li className="sidebar-list-item">
                <Link to="students">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-pie-chart"
                  >
                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                    <path d="M22 12A10 10 0 0 0 12 2v10z" />
                  </svg>
                  <span>Students</span>
                </Link>
              </li>
              <li className="sidebar-list-item">
                <Link to="chats">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-pie-chart"
                  >
                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                    <path d="M22 12A10 10 0 0 0 12 2v10z" />
                  </svg>
                  <span>Chat</span>
                </Link>
              </li>
              <li className="sidebar-list-item">
                <Link to="queries">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-pie-chart"
                  >
                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                    <path d="M22 12A10 10 0 0 0 12 2v10z" />
                  </svg>
                  <span>Queries</span>
                </Link>
              </li>
            </ul>
            <div className="account-info">
              <Link to="profile">
                <div style={{ display: "flex" }}>
                  <div className="account-info-picture">
                    <img src={dummyProfilePic} alt="Account" />
                  </div>
                  <div className="account-info-name">{user?.name} </div>
                </div>
              </Link>
            </div>
            <Link to="profile"></Link>
          </div>
          <div className="app-outlet">
            <Outlet />
          </div>
        </div>
      </section>
    </div>
  );
}

export default SuperAdminHome;
