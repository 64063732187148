/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import iphone from "../../assets/iphone.png";

import leather from "../../assets/leather.png";
import food from "../../assets/food.png";
import book from "../../assets/book.png";
import watch from "../../assets/watch.png";

import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useEffect, useMemo, useState } from "react";
import {
  getAllAds,
  getAllClubs,
  getAllPosts,
  getAllProducts,
  joinOrExitClub,
} from "../../API";
import Loader from "../../components/Loader";
import { getClubsOrMembers } from "../../API/clubs";
import { toast } from "react-toastify";

function StudentDashboard() {
  const [products, setProducts] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [userClubs, setUserClubs] = useState(null);
  const [posts, setPosts] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [ads, setAds] = useState(null);

  const getAds = async () => {
    try {
      const res = await getAllAds({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setAds(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const alertm = () => {
    alert("Joined Successfully");
  };
  const addtoCart = (product) => {
    const previousProducts = JSON.parse(window.localStorage.getItem("cart"));
    if (previousProducts) {
      window.localStorage.setItem(
        "cart",
        JSON.stringify([...previousProducts, product])
      );
    } else {
      window.localStorage.setItem("cart", JSON.stringify([product]));
    }
    setCartItems([...cartItems, product]);
    toast("Added to cart");
  };
  const auth = useAuth();
  const [user, setUser] = useState(
    JSON.parse(window.localStorage.getItem("user"))
  );

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  const productsAndAds = useMemo(() => {
    if (!products || !ads) {
      return [];
    }
    return products.concat(ads);
  }, [ads, products]);
  const getProducts = async () => {
    try {
      const res = await getAllProducts({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setProducts(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const getClubs = async () => {
    try {
      const res = await getAllClubs({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setClubs(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };

  const getPosts = async () => {
    try {
      const res = await getAllPosts({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setPosts(res.data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const handleJoinOrExitClub = async (clubId, deleted) => {
    try {
      const res = await joinOrExitClub({
        club_id: clubId,
        id: user?.id,
        deleted: deleted || 0,
      });
      if (res.response === "success" || res.response === "SUCCESS") {
        toast(res.message || res.Message);
        getClubs();
        getUserClubs();
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const userClubIds = useMemo(() => {
    return (userClubs || []).map((userClub) => userClub.club_id);
  }, [userClubs]);
  const getUserClubs = async (data = {}) => {
    const tempData = {
      user_id: data?.id || user?.id,
      club_id: data?.clubId || -1,
    };
    console.log(tempData);
    try {
      const res = await getClubsOrMembers(tempData);
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setUserClubs(res.data);
      } else {
        setUserClubs([]);
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  useEffect(() => {
    getProducts();
    getClubs();
    getPosts();
    getUserClubs();
    getAds();
  }, []);
  return (
    <>
      {products && clubs && posts && userClubs && ads ? (
        <div className="app-content">
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            <h1 className="app-content-headerText">Student Dashboard</h1>
            {/* <button class="primary_btn">Add</button> */}
            <Link className="primary_btn" type="button" to="studentcart">
              <i className="fa fa-shopping-cart" aria-hidden="true" />
            </Link>
          </div>
          <div className="products-area-wrapper tableView">
            <div className="products-header">
              <div className="products-row1">
                <section>
                  <div className="student">
                    <h1 className="wlcm_student">{`Welcome ${user?.name}`} </h1>
                  </div>
                </section>
                <section className="product_home">
                  <div className="product_container">
                    <h1 style={{ margin: "10px 0" }}>Explore Products</h1>
                    <div className="product_section">
                      {products.map((product) => (
                        <div key={product.id} className="product-card">
                          <div className="badge">Hot</div>
                          <div className="product-tumb">
                            <img src={iphone} alt="" />
                          </div>
                          <div className="product-details">
                            <h4>
                              <a href>{product.product_name}</a>
                            </h4>
                            <p>{product.description}</p>
                            <div className="product-bottom-details">
                              <div className="product-price">
                                {`$ ${product.price}`}
                              </div>
                              <div class="product-links">
                                {/* <a href="">
                                  <i class="fa fa-heart"></i>
                                </a> */}
                                <a onClick={() => addtoCart(product)} href="#">
                                  <i class="fa fa-shopping-cart"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {ads.map((ad) => (
                        <div class="product-card">
                          <div class="badge">ADV</div>
                          <div class="product-tumb">
                            <img
                              src="https://image.shutterstock.com/image-vector/adv-window-vector-flat-minimal-260nw-569413729.jpg"
                              alt=""
                            />
                          </div>
                          <div class="product-details">
                            <h4>
                              <a>{ad.name}</a>
                            </h4>
                            <p>{ad.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="clubs_post">
                      <div>
                        <h1>Explore Clubs</h1>
                        <div className="clubs">
                          {clubs.map((club) => (
                            <div key={club.id} className="club_card">
                              <h1>{club.name}</h1>
                              <p>{club.description}</p>
                              <div
                                onClick={() => {
                                  userClubIds.filter((x) => x == club.id)
                                    .length > 0
                                    ? handleJoinOrExitClub(club.id, 1)
                                    : handleJoinOrExitClub(club.id);
                                }}
                                className="card_readmore"
                              >
                                <b>
                                  {userClubIds.filter((x) => x == club.id)
                                    .length > 0
                                    ? "Exit"
                                    : "Join"}
                                </b>{" "}
                                <i className="fas fa-angle-right" />
                              </div>
                            </div>
                          ))}
                          {/* <div className="club_card">
                            <h1>Programming</h1>
                            <p>Club details ...</p>
                            <a href="#" onClick={alertm}>
                              <div className="card_readmore join">
                                + Join
                                <i className="fas fa-angle-right" />
                              </div>
                            </a>
                          </div>
                          <div className="club_card">
                            <h1>Party</h1>
                            <p>Club details ...</p>
                            <a href="#" onClick={alertm}>
                              <div className="card_readmore join">
                                + Join
                                <i className="fas fa-angle-right" />
                              </div>
                            </a>
                          </div>
                          <div className="club_card">
                            <h1>Hackathon</h1>
                            <p>Club details ...</p>
                            <a href="#" onClick={alertm}>
                              <div className="card_readmore join">
                                + Join
                                <i className="fas fa-angle-right" />
                              </div>
                            </a>
                          </div>
                          <div className="club_card">
                            <h1>Food</h1>
                            <p>Club details ...</p>
                            <div className>
                              <a href="#" onClick={alertm}>
                                <div className="card_readmore join">
                                  + Join
                                  <i className="fas fa-angle-right" />
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="club_card">
                            <h1>Art</h1>
                            <p>Club details ...</p>
                            <a href="#" onClick={alertm}>
                              <div className="card_readmore join">
                                + Join
                                <i className="fas fa-angle-right" />
                              </div>
                            </a>
                          </div>
                          <div className="club_card">
                            <h1>Music</h1>
                            <p>Club details ...</p>
                            <a href="#" onClick={alertm}>
                              <div className="card_readmore join">
                                + Join <i className="fas fa-angle-right" />
                              </div>
                            </a>
                          </div> */}
                        </div>
                      </div>
                      <div>
                        <h1>Explore Post</h1>
                        <div className="clubs">
                          {posts.map((post) => (
                            <div key={post.id} className="club_card">
                              <h1>{post.name}</h1>
                              <p>{post.description}</p>
                              <div className>
                                <Link
                                  state={{ post }}
                                  to="studentdashboardread"
                                >
                                  <div className="card_readmore">
                                    Read..
                                    <i className="fas fa-angle-right" />
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                          {/* <div className="club_card">
                            <h1>Sam</h1>
                            <p>Post details ...</p>
                            <div className>
                              <Link to="studentdashboardread">
                                <div className="card_readmore">
                                  Read..
                                  <i className="fas fa-angle-right" />
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div className="club_card">
                            <h1>Doug</h1>
                            <p>Post details ...</p>
                            <Link to="studentdashboardread">
                              <div className="card_readmore">
                                Read..
                                <i className="fas fa-angle-right" />
                              </div>
                            </Link>
                          </div>
                          <div className="club_card">
                            <h1>James</h1>
                            <p>Post details ...</p>
                            <Link to="studentdashboardread">
                              <div className="card_readmore">
                                Read..
                                <i className="fas fa-angle-right" />
                              </div>
                            </Link>
                          </div>
                          <div className="club_card">
                            <h1>Tony</h1>
                            <p>Post details ...</p>
                            <Link to="studentdashboardread">
                              <div className="card_readmore">
                                Read..
                                <i className="fas fa-angle-right" />
                              </div>
                            </Link>
                          </div>
                          <div className="club_card">
                            <h1>John</h1>
                            <p>Post details ...</p>
                            <Link to="studentdashboardread">
                              <div className="card_readmore">
                                Read..
                                <i className="fas fa-angle-right" />
                              </div>
                            </Link>
                          </div>
                          <div className="club_card">
                            <h1>Steve</h1>
                            <p>Post details ...</p>
                            <Link to="studentdashboardread">
                              <div className="card_readmore">
                                Read..
                                <i className="fas fa-angle-right" />
                              </div>
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="product_list">
                    <ul>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                    </ul>
                  </div> */}
                </section>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default StudentDashboard;
