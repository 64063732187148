/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import projs from "../Js/pro";

function Carousel() {
  return (
    <div>
      {/* Carousel  */}

      <div className="wrapper">
        <script
          src="https://kit.fontawesome.com/82f797382f.js"
          crossOrigin="anonymous"
        ></script>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
          integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <link rel="stylesheet" href="owlcarousel/owl.carousel.min.css"></link>
        <script src={projs} defer></script>
        <div className="carousel">
          <Header />
          <div className="card-1" style={{ backgroundColor: "none" }}>
            <h1>Welcome to UTA Market</h1>
            <p>
              One stop market place where everything available at one place!
            </p>
            <Link
              className="primary_btn"
              to="/signup"
              style={{ marginTop: "100px", marginLeft: "50px" }}
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Carousel;
