import { Link } from "react-router-dom";

function BusinessOwnerAdd() {
    return (
        <div className="app-content">
            <div className="app-content-header" style={{ padding: '20px 0' }}>
                <h1 className="app-content-headerText">Business Owner</h1>
                <Link className="primary_btn" type="button" to="/schooladminhome/businessowner">back</Link>
            </div>
            <div className="products-area-wrapper tableView">
                <section>
                    <div className="signup_wrapper">
                        <div className="signup">
                            <form>
                                <h1>Add Owner</h1>
                                <div className="login_container">
                                    <label htmlFor>Name</label>
                                    <input type="text" name="name" id="name" className="input_box signup_input" placeholder="Username" />
                                    <label htmlFor>Email</label>
                                    <input type="email" name="email" id="email" className="input_box signup_input" placeholder="Email" />
                                    <label htmlFor>Phone</label>
                                    <input type="number" name="phone" id="phone" className="input_box signup_input" placeholder="Mobile Number" />
                                    <label htmlFor="address">Address</label>
                                    <textarea className="input_box signup_input" name="address" id="address" rows={4} cols={50} defaultValue={""} />
                                    <label htmlFor>Role</label>
                                    <select name="role" id="role" className="input_box signup_dd">
                                        <option value>Select Role</option>
                                        <option value="Business Owner">Business Owner</option>
                                        <option value="School Admin">School Admin</option>
                                        <option value="Student">Student</option>
                                    </select>
                                    <label htmlFor>Password</label>
                                    <input type="password" name="password" id="password" className="input_box signup_input" placeholder="Password" />
                                    <label htmlFor>Confirm Password</label>
                                    <input type="password" name="cpassword" id="cpassword" className="input_box signup_input" placeholder="Confirm Password" />
                                    <label>Profile Picture</label>
                                    <input type="file" name="profile" id="profile" className="input_box signup_input" />
                                    <button className="primary_btn" id="submit" value="submit">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    );
}

export default BusinessOwnerAdd;
