/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getOrderDetails } from "../../API/order";
import Loader from "../../components/Loader";

function BuyingOrders() {
  const navigate = useNavigate(-1);
  const [orders, setOrders] = useState(null);
  const [user] = useState(JSON.parse(window.localStorage.getItem("user")));
  const modifiedOrders = useMemo(() => {
    if (!orders) {
      return;
    }
    return orders.map((order) => order.order_details).flat(1);
  }, [orders]);
  const orderDetails = async () => {
    try {
      const res = await getOrderDetails({ buyerId: user.id });
      if (
        res.reponse === "success" ||
        res.reponse === "SUCCESS" ||
        res.response == "success"
      ) {
        setOrders(res.data);
      } else if (res.reponse == "failure") {
        setOrders([]);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };

  useEffect(() => {
    orderDetails();
  }, []);
  return (
    <>
      {orders ? (
        <div className="app-content">
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            <h1 className="app-content-headerText">Orders</h1>
            <button
              className="primary_btn"
              type="button"
              onClick={() => navigate(-1)}
            >
              back
            </button>
          </div>
          <div className="products-area-wrapper tableView">
            <div className="products-header">
              <div className="products-row1">
                <section>
                  <div className="student">
                    <table style={{ width: "100%", textAlign: "center" }}>
                      <tbody>
                        <tr>
                          <th>S No</th>
                          <th>Order ID</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Status</th>
                        </tr>
                        {modifiedOrders.map((order, i) => (
                          <tr>
                            <td>{i}</td>
                            <td>{order.orderId}</td>
                            <td>{order.product_name}</td>
                            <td>{order.quantity}</td>
                            <td>{`$ ${order.price}`}</td>
                            <td>
                              {order.statusId == "1"
                                ? "Order Placed Successfully"
                                : order.statusId == "2"
                                ? "Shipped"
                                : order.statusId == "3"
                                ? "Delivered/Return"
                                : "Pending"}
                            </td>
                          </tr>
                        ))}
                        {/* <tr>
                          <td>2</td>
                          <td>ord-124</td>
                          <td>product 2</td>
                          <td>5</td>
                          <td>$600</td>
                          <td>Payment Success</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>ord-125</td>
                          <td>product 3</td>
                          <td>3</td>
                          <td>$100</td>
                          <td>Payment Declined</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default BuyingOrders;
