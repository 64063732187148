import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createOrUpdateUser } from "../../API";
import ErrorMessage from "../../components/ErrorMessage";
import { useAuth } from "../../hooks/auth";

function SchoolAdminProfileEdit() {
  const auth = useAuth();
  const [user, setUser] = useState(
    JSON.parse(window.localStorage.getItem("user") || "") || null
  );

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  const errorState = {
    name: "",
    email: "",
    phone: "",
    address: "",
    role: "",
    password: "",
    school: "",
  };
  const initialState = {
    name: user?.name,
    email: user?.email,
    phone: user?.phone_number,
    address: user?.address,
    role: "2",
    password: user?.password,
    school: user?.school,
    id: user?.id,
  };
  const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const PHONE_REGEX =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState(errorState);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };

  const isValidated = () => {
    let tempErrors = {};
    //validation
    if (!data?.name?.trim()) {
      tempErrors.name = "Name is a required field";
    }
    if (!data?.email?.trim()) {
      tempErrors.email = "Email is a required field";
    } else if (!EMAIL_REGEX.test(data.email)) {
      tempErrors.email = "Enter a valid email";
    }
    if (!data?.phone?.trim()) {
      tempErrors.phone = "Phone number is a required field";
    } else if (!PHONE_REGEX.test(data.phone)) {
      tempErrors.phone = "Enter a valid phone number";
    }
    if (!data?.address?.trim()) {
      tempErrors.address = "Address is a required field";
    }
    if (!data?.password?.trim()) {
      tempErrors.password = "Password is a required field";
    }

    if (!data?.role) {
      tempErrors.role = "Please select a role";
    }
    // if (!data?.school) {
    //   tempErrors.school = "Please select a school";
    // }

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0 ? true : false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    try {
      const res = await createOrUpdateUser(data);
      if (res.response === "SUCCESS" || res.response === "success") {
        const tempData = {
          id: data.id || -1,
          name: data.name,
          email: data.email,
          phone_number: data.phone,
          address: data.address,
          role_id: data.role,
          password: data.password,
          school_id: data.school || 1,
        };
        window.localStorage.setItem("user", JSON.stringify(tempData));

        toast.success("User updated successfully");
        navigate("/schooladminhome/profile");
      } else {
        toast(res.Message || "Something went wrong");
        setData(initialState);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setData(initialState);
    }
  };
  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">School Admin Profile</h1>
        <Link
          className="primary_btn"
          type="button"
          to="/schooladminhome/profile"
        >
          back
        </Link>
      </div>
      <div className="products-area-wrapper tableView">
        <div className="products-header">
          <div className="products-row1">
            <section>
              <div className="profile_content" style={{ marginTop: "50px" }}>
                {/* Sidenav */}
                <div className="sidenav">
                  <div className="profile">
                    <img
                      src="https://imdezcode.files.wordpress.com/2020/02/imdezcode-logo.png"
                      alt=""
                      width={100}
                      height={100}
                    />
                    <div className="name">{user?.name}</div>
                    <div className="job">School Admin</div>
                  </div>
                  <div className="sidenav-url">
                    <div className="url">
                      <div onClick={handleSubmit} className="active">
                        Update Profile
                      </div>
                      <hr align="center" />
                    </div>
                    <div className="url">
                      <a href="#settings">Settings</a>
                      <hr align="center" />
                    </div>
                  </div>
                </div>
                {/* End */}
                {/* Main */}
                <div className="main">
                  <h2>IDENTITY</h2>
                  <div className="card">
                    <div className="card-body">
                      {/* <i class="fa fa-pen fa-xs edit"></i> */}
                      <table style={{ border: "none" }}>
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <td>:</td>
                            <td>
                              <input
                                name="name"
                                id="name"
                                value={data.name}
                                onChange={handleInputChange}
                                type="text"
                                className="input_box"
                                style={{ marginLeft: "10px" }}
                              />
                              {errors.name && (
                                <ErrorMessage message={errors.name} />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>:</td>
                            <td>
                              <input
                                name="email"
                                id="email"
                                value={data.email}
                                onChange={handleInputChange}
                                type="email"
                                className="input_box"
                                style={{ marginLeft: "10px" }}
                              />
                              {errors.email && (
                                <ErrorMessage message={errors.email} />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>:</td>
                            <td>
                              <input
                                name="address"
                                id="address"
                                value={data.address}
                                onChange={handleInputChange}
                                type="text"
                                className="input_box"
                                style={{ marginLeft: "10px" }}
                              />
                              {errors.address && (
                                <ErrorMessage message={errors.address} />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Password</td>
                            <td>:</td>
                            <td>
                              <input
                                name="password"
                                id="password"
                                value={data.password}
                                onChange={handleInputChange}
                                type="password"
                                className="input_box"
                                style={{ marginLeft: "10px" }}
                              />
                              {errors.password && (
                                <ErrorMessage message={errors.password} />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Role</td>
                            <td>:</td>
                            <td>Business Owner</td>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td>:</td>
                            <td>
                              <input
                                name="phone"
                                id="phone"
                                value={data.phone}
                                onChange={handleInputChange}
                                type="text"
                                className="input_box"
                                style={{ marginLeft: "10px" }}
                              />
                              {errors.phone && (
                                <ErrorMessage message={errors.phone} />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolAdminProfileEdit;
