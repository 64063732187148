/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import iphone from "../../assets/iphone.png";
import { Link } from "react-router-dom";
import watch from "../../assets/watch.png";
import { useEffect, useState } from "react";
import { createOrUpdateProduct, getAllProducts } from "../../API/product";
import { useAuth } from "../../hooks/auth";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

function StudentProduct() {
  const [products, setProducts] = useState(null);
  const auth = useAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  const getProducts = async () => {
    try {
      const res = await getAllProducts({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setProducts(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const handleCreateOrUpdateProducts = async (data) => {
    console.log(data);
    const tempData = {
      product_id: data.id,
      product_name: data.product_name,
      short_desc: data.short_desc,
      description: data.description,
      price: data.price,
      stock_count: data.stock_count,
      created_by: user?.id,
      is_deleted: data.deleted || 1,
    };
    try {
      const res = await createOrUpdateProduct(tempData);
      if (
        res.reponse === "success" ||
        res.reponse === "SUCCESS" ||
        res.response == "success" ||
        res.resposne == "SUCCESS" ||
        res.resposne == "success"
      ) {
        toast.success("Deleted successfully");
        getProducts();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      {products ? (
        <div className="app-content">
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            <h1 className="app-content-headerText">Products</h1>
            <Link type="button" to="studentproductsadd" className="primary_btn">
              Add
            </Link>
          </div>
          <div className="products-area-wrapper tableView">
            <div className="products-header">
              <div className="product-cell image">
                Items
                <button className="sort-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                    />
                  </svg>
                </button>
              </div>
              {/* <div className="product-cell category">
                    Category
                    <button className="sort-button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                        />
                      </svg>
                    </button>
                  </div> */}
              {/* <div className="product-cell status-cell">
                    Status
                    <button className="sort-button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                        />
                      </svg>
                    </button>
                  </div> */}
              {/* <div className="product-cell sales">
                    Sales
                    <button className="sort-button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                        />
                      </svg>
                    </button>
                  </div> */}
              <div className="product-cell stock">
                Stock
                <button className="sort-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                    />
                  </svg>
                </button>
              </div>
              <div className="product-cell price">
                Price
                <button className="sort-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                    />
                  </svg>
                </button>
              </div>
              <div className="product-cell price">
                Action
                <button className="sort-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {products.map((product) => {
              if (user?.id != product?.owner_id) {
                return null;
              }
              return (
                <div className="products-row">
                  <button className="cell-more-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-more-vertical"
                    >
                      <circle cx={12} cy={12} r={1} />
                      <circle cx={12} cy={5} r={1} />
                      <circle cx={12} cy={19} r={1} />
                    </svg>
                  </button>
                  <div className="product-cell image">
                    <img src={iphone} alt="product" />
                    <span>{product.product_name}</span>
                  </div>
                  {/* <div className="product-cell category">
                      <span className="cell-label">Category:</span>Accessory
                    </div> */}
                  {/* <div className="product-cell status-cell">
                      <span className="cell-label">Status:</span>
                      <span className="status active">Active</span>
                    </div> */}
                  {/* <div className="product-cell sales">
                      <span className="cell-label">Sales:</span>11
                    </div> */}
                  <div className="product-cell stock">
                    <span className="cell-label">Stock:</span>
                    {product.stock_count}
                  </div>
                  <div className="product-cell price">
                    <span className="cell-label">Price:</span>
                    {`$${product.price}`}
                  </div>
                  <div className="product-cell action">
                    <span className="cell-label">Action</span>
                    {user?.id == product?.owner_id ? (
                      <>
                        <Link
                          state={{ product }}
                          to="studentproductsedit"
                          className="edit_prod"
                        >
                          <i className="fa fa-pencil" aria-hidden="true" />
                        </Link>{" "}
                        <a
                          type="button"
                          className="del_prod"
                          onClick={() => handleCreateOrUpdateProducts(product)}
                        >
                          <i className="fa fa-trash" aria-hidden="true" />
                        </a>
                      </>
                    ) : (
                      <div>NA</div>
                    )}
                  </div>
                </div>
              );
            })}
            {/* <div className="products-row">
                  <button className="cell-more-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-more-vertical"
                    >
                      <circle cx={12} cy={12} r={1} />
                      <circle cx={12} cy={5} r={1} />
                      <circle cx={12} cy={19} r={1} />
                    </svg>
                  </button>
                  <div className="product-cell image">
                    <img src={iphone} alt="product" />
                    <span>Iphone</span>
                  </div>
                  <div className="product-cell category">
                    <span className="cell-label">Category:</span>Accessory
                  </div>
                  <div className="product-cell status-cell">
                    <span className="cell-label">Status:</span>
                    <span className="status active">Active</span>
                  </div>
                  <div className="product-cell sales">
                    <span className="cell-label">Sales:</span>11
                  </div>
                  <div className="product-cell stock">
                    <span className="cell-label">Stock:</span>36
                  </div>
                  <div className="product-cell price">
                    <span className="cell-label">Price:</span>$560
                  </div>
                  <div className="product-cell action">
                    <span className="cell-label">Action</span>
                    <Link to="businessproductsedit" className="edit_prod">
                      <i className="fa fa-pencil" aria-hidden="true" />
                    </Link>{" "}
                    <a
                      type="button"
                      className="del_prod"
                      onclick="return confirm('Are you sure?');"
                    >
                      <i className="fa fa-trash" aria-hidden="true" />
                    </a>
                  </div>
                </div>
                <div className="products-row">
                  <button className="cell-more-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-more-vertical"
                    >
                      <circle cx={12} cy={12} r={1} />
                      <circle cx={12} cy={5} r={1} />
                      <circle cx={12} cy={19} r={1} />
                    </svg>
                  </button>
                  <div className="product-cell image">
                    <img src={watch} alt="product" />
                    <span>Watch</span>
                  </div>
                  <div className="product-cell category">
                    <span className="cell-label">Category:</span>Wearable
                  </div>
                  <div className="product-cell status-cell">
                    <span className="cell-label">Status:</span>
                    <span className="status disabled">Disabled</span>
                  </div>
                  <div className="product-cell sales">
                    <span className="cell-label">Sales:</span>6
                  </div>
                  <div className="product-cell stock">
                    <span className="cell-label">Stock:</span>46
                  </div>
                  <div className="product-cell price">
                    <span className="cell-label">Price:</span>$710
                  </div>
                  <div className="product-cell action">
                    <span className="cell-label">Action</span>
                    <Link to="businessproductsedit" className="edit_prod">
                      <i className="fa fa-pencil" aria-hidden="true" />
                    </Link>{" "}
                    <a
                      type="button"
                      className="del_prod"
                      onclick="return confirm('Are you sure?');"
                    >
                      <i className="fa fa-trash" aria-hidden="true" />
                    </a>
                  </div>
                </div> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default StudentProduct;
