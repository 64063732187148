/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllQueries } from "../../API/queries";
import Loader from "../../components/Loader";

function SuperAdminContactQueries() {
  const [queries, setQueries] = useState(null);
  const getQueries = async () => {
    try {
      const res = await getAllQueries({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setQueries(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  useEffect(() => {
    getQueries();
  }, []);
  return (
    <>
      {queries ? (
        <div className="app-content">
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            {/* <h1 class="app-content-headerText">Super Admin Dashboard</h1> */}
            {/* <a type="button" href="studentProductAdd.html" class="primary_btn">Add</a> */}
          </div>
          <div className="products-area-wrapper tableView">
            <div className="products-header">
              <div className="products-row1">
                <section>
                  <h1 className>Queries</h1>
                  <section>
                    <div className="student" style={{ marginTop: "20px" }}>
                      <table style={{ width: "100%", textAlign: "center" }}>
                        <tbody>
                          <tr>
                            <th>S No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Message</th>
                            <th>Status</th>
                          </tr>
                          {queries.map((query, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{query.name}</td>
                              <td>{query.email_address}</td>
                              <td>{query.message}</td>
                              <td>
                                {query.status !== 3 ? (
                                  <a
                                    href={`mailto:${query.email_address}`}
                                    className="edit_prod"
                                  >
                                    <i
                                      className="fa fa-reply"
                                      aria-hidden="true"
                                    />
                                  </a>
                                ) : (
                                  <div>Resolved</div>
                                )}
                              </td>
                            </tr>
                          ))}
                          {/* <tr>
                            <td>1</td>
                            <td>Anusha</td>
                            <td>Anusha@gmail.com</td>
                            <td>Hi there, I am interested</td>
                            <td>
                              <a
                                href="mailto:anusha@gmail.com"
                                className="edit_prod"
                              >
                                <i className="fa fa-reply" aria-hidden="true" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Sam</td>
                            <td>Sam@gmail.com</td>
                            <td>+1 234 6789</td>
                            <td>I need your help in tracking my order</td>
                            <td>
                              <a
                                href="mailto:anusha@gmail.com"
                                className="edit_prod"
                              >
                                <i className="fa fa-reply" aria-hidden="true" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Andrew</td>
                            <td>andrew@gmail.com</td>
                            <td>+1 2345 6799</td>
                            <td>
                              My account has some login issue could you please
                              help me
                            </td>
                            <td>
                              <a
                                href="mailto:anusha@gmail.com"
                                className="edit_prod"
                              >
                                <i className="fa fa-reply" aria-hidden="true" />
                              </a>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </section>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default SuperAdminContactQueries;
