import { BASE_URL_API } from "./base";

export const getAllQueries = async (data) => {
  const tempData = {
    query_status: data.id || "-1",
  };
  const rawResponse = await fetch(`${BASE_URL_API}/getqueries.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();

  return content;
};
export const createOrUpdateQuery = async (data) => {
  const tempData = {
    query_id: data.id || -1,
    person_name: data.name,
    email_address: data.email,
    query_message: data.message,
    query_status: 0,
    is_deleted: 0,
  };
  const rawResponse = await fetch(`${BASE_URL_API}/createorupdatequery.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();

  return content;
};
