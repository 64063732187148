import { BASE_URL_API } from "./base";

export const getAllLoginDetails = async (data) => {
  const tempData = {
    username: data?.email,
    password: data?.password,
  };
  const rawResponse = await fetch(`${BASE_URL_API}/login.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();

  return content;
};
