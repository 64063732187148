import { BASE_URL_API } from "./base";

export const getAllClubs = async (data) => {
  console.log(data.id);
  const tempData = {
    user_id: data.id || "-1",
  };
  const rawResponse = await fetch(`${BASE_URL_API}/getclubs.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();

  return content;
};
export const joinOrExitClub = async (data) => {
  const tempData = {
    club_id: data.club_id,
    user_id: data.id,
    is_deleted: data.deleted || 0,
  };
  const rawResponse = await fetch(`${BASE_URL_API}/joinorexitclub.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();

  return content;
};
export const createOrUpdateClub = async (data) => {
  const rawResponse = await fetch(`${BASE_URL_API}/createorupdateclub.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const content = await rawResponse.json();

  return content;
};
export const getClubsOrMembers = async (data) => {
  const rawResponse = await fetch(`${BASE_URL_API}/getclubs_or_members.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const content = await rawResponse.json();

  return content;
};
