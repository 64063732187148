/* eslint-disable jsx-a11y/alt-text */

import Footer from "../components/footer";
import Header from "../components/header";
import projs from "../Js/pro";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ErrorMessage from "../components/ErrorMessage";
import { getAllLoginDetails } from "../API/login";
import { useAuth } from "../hooks/auth";
import { toast } from "react-toastify";

function Login() {
  const initialState = {
    email: "",
    password: "",
  };
  const [errors, setErrors] = useState(initialState);
  const [data, setData] = useState(initialState);

  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };
  let navigate = useNavigate();
  const isValidated = () => {
    let tempErrors = {};
    //validation

    if (!data?.email?.trim()) {
      tempErrors.email = "Email is a required field";
    }
    if (!data?.password?.trim()) {
      tempErrors.password = "Password is a required field";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0 ? true : false;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(initialState);
    if (!isValidated()) {
      return;
    }

    try {
      const res = await getAllLoginDetails(data);

      if (res.response === "success") {
        window.localStorage.setItem("user", JSON.stringify(res.data));

        if (res.data.role_id == "1") {
          navigate("/businessownerhome/dashboard", { replace: true });
          return false;
        } else if (res.data.role_id == "2") {
          navigate("/schooladminhome/dashboard", { replace: true });

          return false;
        } else if (res.data.role_id == "3") {
          navigate("/studenthome/dashboard", { replace: true });
          return false;
        } else if (res.data.role_id == "4") {
          navigate("/superadminhome/dashboard", { replace: true });
          return false;
        }
      } else if (res.response === "Failure") {
        toast.error("Please check your login credentials and try again");
        setData(initialState);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setData(initialState);
    }
  };

  return (
    <div>
      <script
        src="https://kit.fontawesome.com/82f797382f.js"
        crossOrigin="anonymous"
      ></script>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <link rel="stylesheet" href="owlcarousel/owl.carousel.min.css"></link>
      <script src={projs} defer></script>
      <Header />
      <section>
        <div className="login_wrapper">
          <div className="login">
            <form onSubmit={handleSubmit}>
              <h1>Login</h1>
              <div className="login_container">
                <label for="">Email</label>
                <input
                  value={data?.email}
                  type="email"
                  name="email"
                  id="email"
                  className="input_box login_input"
                  placeholder="john343@gmail.com"
                  onChange={handleInputChange}
                />
                {errors.email && <ErrorMessage message={errors.email} />}

                <label for="">Password</label>
                <input
                  value={data?.password}
                  type="password"
                  name="password"
                  id="password"
                  className="input_box login_input"
                  placeholder="Password"
                  onChange={handleInputChange}
                />
                {errors.password && <ErrorMessage message={errors.password} />}
                <button className="primary_btn" type="submit">
                  Login
                </button>
                <span>
                  {" "}
                  Not have an account <Link to="/signup">Signup</Link>
                </span>
              </div>
            </form>
            <span>
              {" "}
              <Link to="/forgotpassword">Forgot Password</Link>
            </span>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Login;
