export const getRole = (id) => {
  switch (id) {
    case "1":
      return "Business Owner";
    case "2":
      return "School Admin";
    case "3":
      return "Student";
    case "4":
      return "Super Admin";
    default:
      return "NA";
  }
};
