/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllAds } from "../../API";
import { createOrUpdateAds } from "../../API/ads";
import Loader from "../../components/Loader";
import { useAuth } from "../../hooks/auth";

function Ads() {
  const [ads, setAds] = useState(null);
  const auth = useAuth();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = window.localStorage.getItem("user");
    if (data !== null) setUser(JSON.parse(data));
  }, []);
  const getAds = async () => {
    try {
      const res = await getAllAds({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setAds(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const handleCreateOrUpdateAds = async (data) => {
    const tempData = {
      ad_id: data.id,
      ad_name: data.name,
      ad_description: data.description,
      created_by: data.owner_id,
      is_deleted: data.delete || 0,
    };
    try {
      const res = await createOrUpdateAds(tempData);
      if (
        res.reponse === "success" ||
        res.reponse === "SUCCESS" ||
        res.response == "success"
      ) {
        toast.success("Deleted successfully");
        getAds();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getAds();
  }, []);
  return (
    <>
      {ads ? (
        <div className="app-content">
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            <h1 className="app-content-headerText">Ads</h1>
            <Link className="primary_btn" type="button" to="post">
              Add Ads
            </Link>
          </div>
          <div className="products-area-wrapper tableView">
            <div className="products-header">
              <div className="products-row1">
                <section>
                  <div className="student_club">
                    {ads.map((ad) => {
                      if (user?.id != ad?.owner_id) {
                        return null;
                      }

                      return (
                        <div className="service_card_wrapper">
                          <img
                            src="https://image.shutterstock.com/image-vector/adv-window-vector-flat-minimal-260nw-569413729.jpg"
                            alt=""
                          />
                          <p className="card_header">{ad?.name}</p>
                          <p className="card_content">{ad?.description}</p>
                          {user?.id == ad?.owner_id ? (
                            <div className>
                              <Link state={{ ad: ad }} to="edit">
                                <div
                                  className="card_readmore"
                                  style={{ color: "#000" }}
                                >
                                  <b /> Edit
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  />
                                </div>
                              </Link>
                              <a
                                href="#"
                                onClick={() =>
                                  handleCreateOrUpdateAds({
                                    ...ad,
                                    delete: "1",
                                  })
                                }
                              >
                                <div className="card_readmore ">
                                  Delete <i className="fas fa-angle-right" />
                                </div>
                              </a>
                            </div>
                          ) : (
                            <Link state={{ ad: ad }} to={"read"}>
                              <div className="card_readmore ">
                                Read <i className="fas fa-angle-right" />
                              </div>
                            </Link>
                          )}
                        </div>
                      );
                    })}
                    {/* <div className="service_card_wrapper">
                      <div className="pic" />
                      <p className="card_header">Women Leather Bag</p>
                      <p className="card_content">
                        30% OFF use promo code:LEATHER
                      </p>
                      <a href="#" onclick="alert('succesfully deleted')">
                        <div className="card_readmore delete">
                          Delete <i className="fas fa-angle-right" />
                        </div>
                      </a>
                    </div>
                    <div className="service_card_wrapper">
                      <div className="pic2" />
                      <p className="card_header">Buy 2 GET 1 Free</p>
                      <p className="card_content">
                        Offer Valid for 5 days only!
                      </p>
                      <div className>
                        <Link to="edit">
                          <div
                            className="card_readmore"
                            style={{ color: "#000" }}
                          >
                            <b /> Edit{" "}
                            <i className="fa fa-pencil" aria-hidden="true" />
                          </div>
                        </Link>
                      </div>
                      <a href="#" onclick="alert('succesfully deleted')">
                        <div className="card_readmore delete">
                          Delete <i className="fas fa-angle-right" />
                        </div>
                      </a>
                    </div>
                    <div className="service_card_wrapper">
                      <div className="pic3" />
                      <p className="card_header">Flat 10% OFF</p>
                      <p className="card_content">
                        Avail only on first purchase
                      </p>
                      <a href="#" onclick="alert('succesfully deleted')">
                        <div className="card_readmore delete">
                          Delete <i className="fas fa-angle-right" />
                        </div>
                      </a>
                    </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Ads;
