/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import serviceImg from "../assets/service.jpg";
import serviceImg1 from "../assets/services1.jpeg";
import serviceImg2 from "../assets/services2.jpeg";
import serviceImg3 from "../assets/services3.jpeg";
import Footer from "../components/footer";
import Header from "../components/header";
import projs from "../Js/pro";

function Service() {
  return (
    <div>
      <script
        src="https://kit.fontawesome.com/82f797382f.js"
        crossOrigin="anonymous"
      ></script>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <link rel="stylesheet" href="owlcarousel/owl.carousel.min.css"></link>
      <script src={projs} defer></script>
      <Header />
      <section className="page_content">
        <section className="service_hero_section">
          <div
            className="service_hero_div"
            style={{ backgroundImage: `url(${serviceImg})` }}
          >
            <div className="overlay">
              <h1 className="contact_us">Our Services</h1>
            </div>
          </div>
        </section>

        <section>
          <div className="service_wrapper">
            <div className="service_card_wrapper">
              <img
                style={{ height: "200px", width: "85%" }}
                src={serviceImg1}
              ></img>
              <p className="card_header">
                Exchange information with your peers
              </p>
              <p className="card_content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                harum amet, unde veritatis ipsum itaque laborum placeat, facere
                voluptatibus necessitatibus quisquam corrupti nisi vitae?
                Maiores iste laboriosam nostrum distinctio tenetur.
              </p>
            </div>

            <div className="service_card_wrapper">
              <img
                style={{ height: "200px", width: "85%" }}
                src={serviceImg2}
              ></img>
              <p className="card_header">Check out the clubs on the campus</p>
              <p className="card_content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                harum amet, unde veritatis ipsum itaque laborum placeat, facere
                voluptatibus necessitatibus quisquam corrupti nisi vitae?
                Maiores iste laboriosam nostrum distinctio tenetur.
              </p>
            </div>

            <div className="service_card_wrapper">
              <img
                style={{ height: "200px", width: "85%" }}
                src={serviceImg3}
              ></img>
              <p className="card_header">
                Trade books and other things with your peers
              </p>
              <p className="card_content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                harum amet, unde veritatis ipsum itaque laborum placeat, facere
                voluptatibus necessitatibus quisquam corrupti nisi vitae?
                Maiores iste laboriosam nostrum distinctio tenetur.
              </p>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </div>
  );
}

export default Service;
