/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import NavBar from './navbar';

function Header() {

    return (
        <NavBar />
    );
}

export default Header;
