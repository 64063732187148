import { Link } from "react-router-dom";

function SuperSchoolAdminRead() {
    return (

        <div className="app-content">
            <div className="app-content-header" style={{ padding: '20px 0' }}>
                <h1 className="app-content-headerText">School Admin</h1>
                <Link type="button" to="/superadminhome/schooladmins" className="primary_btn">Back</Link>
            </div>
            <div className="products-area-wrapper tableView">
                <section>
                    <div className="main">
                        {/* <h2>IDENTITY</h2> */}
                        <div className="card">
                            <div className="card-body">
                                {/* <i class="fa fa-pen fa-xs edit"></i> */}
                                <table style={{ borderCollapse: 'none', border: 'none' }}>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>:</td>
                                            <td>John Deo</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>:</td>
                                            <td>mymail@gmail.com</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>:</td>
                                            <td>My Address</td>
                                        </tr>
                                        <tr>
                                            <td>Password</td>
                                            <td>:</td>
                                            <td>**********</td>
                                        </tr>
                                        <tr>
                                            <td>Role</td>
                                            <td>:</td>
                                            <td>School Admin</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>:</td>
                                            <td>+1 123 456778</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    );
}

export default SuperSchoolAdminRead;
