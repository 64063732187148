/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useEffect, useMemo, useState } from "react";
import { getOrderDetails } from "../../API/order";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

function OrderSummary() {
  const [orders, setOrders] = useState(null);
  const [sellOrders, setSellOrders] = useState(null);
  const [buyOrders, setBuyOrders] = useState(null);
  const [user] = useState(JSON.parse(window.localStorage.getItem("user")));
  const [date, setDate] = useState({
    from: "",
    to: "",
  });
  const csvOrders = useMemo(() => {
    if (!orders) {
      return [];
    }
    return orders.map((order) => order.order_details).flat(1);
  }, [orders]);
  const sellOrderData = useMemo(() => {
    if (!sellOrders) {
      return;
    }
    return sellOrders
      .map((order) => order.order_details)
      .flat(1)
      .map((item) => [item.product_name.slice(0, 15) + "...", item.quantity]);
  }, [sellOrders]);
  const buyOrderData = useMemo(() => {
    if (!buyOrders) {
      return;
    }
    return buyOrders
      .map((order) => order.order_details)
      .flat(1)
      .map((item) => [item.product_name.slice(0, 15) + "...", item.quantity]);
  }, [buyOrders]);
  const orderDetails = async (date) => {
    const tempData = {
      createdDate: date?.from,
      endDate: date?.to,
    };
    try {
      const res = await getOrderDetails({ buyerId: user.id, ...tempData });
      const res2 = await getOrderDetails({ sellerId: user.id, ...tempData });
      if (
        (res.reponse === "success" ||
          res.reponse === "SUCCESS" ||
          res.response == "success") &&
        (res2.reponse === "success" ||
          res2.reponse === "SUCCESS" ||
          res2.response == "success")
      ) {
        setOrders([...res.data, ...res2.data]);
        setSellOrders(res2.data);
        setBuyOrders(res.data);
      } else if (
        (res.reponse === "success" ||
          res.reponse === "SUCCESS" ||
          res.response == "success") &&
        (res2.reponse === "failure" ||
          res2.reponse === "SUCCESS" ||
          res2.response == "success")
      ) {
        setOrders([...res.data]);
        setSellOrders([]);
        setBuyOrders(res.data);
      }
      else if (
        (res.reponse === "failure" ||
          res.reponse === "SUCCESS" ||
          res.response == "success") &&
        (res2.reponse === "success" ||
          res2.reponse === "SUCCESS" ||
          res2.response == "success")
      ) {
        setOrders([]);
        setSellOrders([...res2.data]);
        setBuyOrders([]);
      }
      else if (res.reponse == "failure") {
        setOrders([]);
        setSellOrders([]);
        setBuyOrders([]);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    orderDetails();
  }, []);
  return (
    <>
      {orders ? (
        <div className="app-content">
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            <h1 className="app-content-headerText">Order Summary</h1>
          </div>
          <div className="products-area-wrapper tableView">
            <div className="products-header">
              <div className="products-row1">
                <section>
                  {/* <div className="bo_stats">
                                      <div>
                                          <Link to="studentorders" className="student_orders1">Selling Orders</Link>
                                      </div>
                                      <div>
                                          <Link to="studentbuying" className="student_orders1">Buying Orders</Link>
                                      </div>
                                  </div> */}
                </section>
              </div>
            </div>
            <div className="bo_stats">
              <div>
                <Link to="sellingorders" className="student_orders1">
                  Selling Orders
                </Link>
              </div>
              {user.role_id == "1" ? null : (
                <div>
                  <Link to="buyingorders" className="student_orders1">
                    Buying Orders
                  </Link>
                </div>
              )}
            </div>
            <h1
              className="app-content-headerText"
              style={{ backgroundColor: "#fff" }}
            >
              Reports
            </h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!date.from || !date.to) {
                  return toast.error("Please select from and to dates");
                }
                orderDetails(date);
              }}
            >
              <div className="date">
                <div style={{ marginRight: "50px", paddingBottom: "20px" }}>
                  <label htmlFor className="marginright10">
                    From
                  </label>
                  <input
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => setDate({ ...date, from: e.target.value })}
                    type="date"
                    className="date_input"
                  />
                </div>
                <div style={{ marginRight: "50px", paddingBottom: "20px" }}>
                  <label
                    htmlFor
                    style={{ marginR: "10px" }}
                    className="marginright30"
                  >
                    To
                  </label>
                  <input
                    onChange={(e) => setDate({ ...date, to: e.target.value })}
                    max={new Date().toISOString().split("T")[0]}
                    type="date"
                    className="date_input"
                  />
                </div>
                <div
                  style={{ marginRight: "50px", paddingBottom: "20px" }}
                  className="marginleft80"
                >
                  <button className="primary_btn" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <div class="charts">
              <Chart
                className="chartsbig"
                chartType="PieChart"
                data={[["Items", "quantity"], ...sellOrderData]}
                width="100%"
                // height="400px"
                legendToggle
              />
              {user.role_id == "1" ? null : (
                <Chart
                  chartType="PieChart"
                  className="chartsbig"
                  data={[["items", "quantity"], ...buyOrderData]}
                  width="100%"
                  // height="400px"
                  legendToggle
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default OrderSummary;
