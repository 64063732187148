import React from "react";
import "../CSS/loader.css";
const Loader = () => {
  return (
    <div className="container vertical-center">
      <div className="loader"></div>
    </div>
  );
};
export default Loader;
