import { BASE_URL_API } from "./base";

export const getAllOrders = async (data) => {
  const tempData = {
    order_id: data.orderId || -1,
    buyer_id: data.id || -1,
    seller_id: data.sellerId || -1,
    product_id: data.productId || -1,
  };
  const rawResponse = await fetch(`${BASE_URL_API}/getorderdetails.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();

  return content;
};
export const createOrder = async (data, items) => {
  console.log(items);
  const tempData = {
    order_id: -1,
    ordered_date: data.date,
    ordered_by: data.id,
    is_deleted: 0,
    items: items,
  };
  const rawResponse = await fetch(`${BASE_URL_API}/createorder.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();
   console.log(content);
  return content;
};
export const getOrderDetails = async (data) => {
  const tempData = {
    order_id: -1,
    buyer_id: data.buyerId || -1,
    seller_id: data.sellerId || -1,
    product_id: -1,
    order_start_date: data.createdDate || "1000-01-01",
    order_end_date: data.endDate || "1000-01-01",
  };
  const rawResponse = await fetch(`${BASE_URL_API}/getorderdetails.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();

  return content;
};
export const updateOrderStatus = async (data) => {
  const tempData = {
    order_id: data.orderId,
    product_id: data.productId,
    status_id: data.statusId,
  };
  const rawResponse = await fetch(`${BASE_URL_API}/updateorderstatus.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempData),
  });
  const content = await rawResponse.json();

  return content;
};
