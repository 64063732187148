
import React from 'react';
import { NavLink } from 'react-router-dom';
import '../CSS/navbar.css'
import owner from '../assets/owner.png'

function NavBar() {
    const [click, setClick] = React.useState(false);

    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);

    return (
        <div>
            <div className={click ? "main-container" : ""} onClick={() => Close()} />
            <nav className="navbar" onClick={e => e.stopPropagation()}>
                <div className="nav-container">
                    <div className="logo_header">
                        <img src={owner} style={{ height: '80px' }} />

                        <div className="nav-icon" onClick={handleClick}>
                            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
                        </div>
                    </div>

                    <div style={{ marginTop: '30px' }}>
                        <input type="search" placeholder="search here" className="search" />
                        <i className="fa fa-magnifying-glass search_icon" ></i>
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="/"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="/about"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                About
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="/service"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                Services
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="/contact"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                Contact
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a
                                href="http://lxv5528.uta.cloud/"
                                className="nav-links"
                                rel="noreferrer">

                                Blog
                            </a>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                end
                                to="/login"
                                activeClassName="active"
                                className="nav-links"
                                onClick={click ? handleClick : null}
                            >
                                Login/Signup
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;