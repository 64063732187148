import { Link, useLocation } from "react-router-dom";
import clubImage from "../../assets/club1.png";

function SchoolAdminClubRead() {
  const location = useLocation();
  const { club } = location.state;
  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">Club</h1>
        <Link className="primary_btn" type="button" to="/schooladminhome/clubs">
          back
        </Link>
      </div>
      <div className="products-area-wrapper tableView">
        <div className="products-header">
          <div className="products-row1">
            <section>
              <div
                className="student"
                style={{
                  backgroundColor: "#fff",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <div className="read_post">
                  <img src={clubImage} alt="img" className="read_post_img" />
                  <h1 style={{ padding: "20px 0", fontSize: "40px" }}>
                    {club?.name}
                  </h1>
                  <p>{club?.description}</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolAdminClubRead;
