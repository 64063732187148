/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import aboutImg from "../assets/about.jpg";
import Footer from "../components/footer";
import Header from "../components/header";
import projs from "../Js/pro";

function About() {
  return (
    <div>
      <script
        src="https://kit.fontawesome.com/82f797382f.js"
        crossOrigin="anonymous"
      ></script>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <link rel="stylesheet" href="owlcarousel/owl.carousel.min.css"></link>
      <script src={projs} defer></script>
      <Header />

      <section className="page_content">
        <section className="about_hero_section">
          <div
            className="about_hero_div"
            style={{ backgroundImage: `url(${aboutImg})` }}
          >
            <div className="overlay">
              <h1 className="contact_us">About Us</h1>
            </div>
          </div>
        </section>

        <section className="about_us_section">
          <div>
            <h1 className="about" style={{ textAlign: "center" }}>
              About Us
            </h1>
            <p>
              {" "}
              This a website for students exchange information, form
              organizations/clubs, buy/sell/trade goods over a marketplace, and
              monetize this product via selling ad space to local businesses.
              This product should be easily portable to other institutions as
              well.{" "}
            </p>
            <p>
              {" "}
              The student information exchange portal is a 3-sided marketplace
              the success of which depends on the onboarding and usage by the
              below 3 groups of users:{" "}
            </p>
            <ul>
              <li>student service providers,</li>
              <li>service receivers and </li>
              <li>advertisement creators. </li>
            </ul>
            <p>
              {" "}
              This application has the potential for network effects and it is
              important to onboard the users on all the sides of the market.{" "}
            </p>
            <p>
              {" "}
              The initial launch of the product will be a minimum viable product
              that could drive user adoption and bring local businesses onto it.
              The better version of the product could be released based on the
              user feedback and adoption.
            </p>
          </div>
        </section>
      </section>
      <Footer />
    </div>
  );
}

export default About;
