import { useEffect, useMemo, useState } from "react";
import Chart from "react-google-charts";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllClubs, getAllPosts, getAllUsers } from "../../API";
import Loader from "../../components/Loader";
import { CSVLink } from "react-csv";

function SchoolAdminDashboard() {
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(
    JSON.parse(window.localStorage.getItem("user"))
  );
  console.log(user);
  const [posts, setPosts] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [date, setDate] = useState({
    from: "",
    to: "",
  });

  const getUsers = async (date) => {
    try {
      const res = await getAllUsers({
        createdDate: date?.from,
        endDate: date?.to,
        school: user.schoolId,
      });
      if (res.reponse === "success") {
        setUsers(res.data);
      } else {
        setUsers([]);
        toast.error("No data found on users");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const getPosts = async () => {
    try {
      const res = await getAllPosts({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setPosts(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const getClubs = async () => {
    try {
      const res = await getAllClubs({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setClubs(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const csvOrders = useMemo(() => {
    if (!users || !posts || !clubs) {
      return [];
    }
    return users.concat(posts).concat(clubs);
  }, [clubs, posts, users]);
  const businessOwners = useMemo(() => {
    return (users || []).filter((el) => el.user_type_id == 1);
  }, [users]);
  const students = useMemo(() => {
    return (users || []).filter((el) => el.user_type_id == 3);
  }, [users]);
  useEffect(() => {
    getUsers();
    getPosts();
    getClubs();
  }, []);
  return (
    <>
      {users && posts && clubs ? (
        <div className="app-content SAHome">
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            <h1 className="app-content-headerText">School Admin Dashboard</h1>
            {/* <a class="primary_btn" type="button" href="studentPost.html">back</a> */}
          </div>
          <div style={{ padding: "10px", width: "100%" }}>
            <div className="products-area-wrapper-dashboard tableView">
              <div className="products-header">
                <div className="products-row1">
                  <section>
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        marginTop: "25px",
                      }}
                    >
                      Welcome School Admin
                    </h1>
                    <div
                      className="student1 admin_stats"
                      style={{ overflow: "none" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                        className="admin_card_panel"
                      >
                        <div className="admin_card">
                          {students.length} <br /> STUDENTS
                        </div>
                        <div className="admin_card">
                          {businessOwners.length} <br /> BUSINESS OWNERS
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                        className="admin_card_panel"
                      >
                        <div className="admin_card">
                          {posts.length} <br /> POSTS
                        </div>
                        <div className="admin_card">
                          {clubs.length} <br /> CLUBS
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <h1
              className="app-content-headerText"
              style={{ backgroundColor: "#fff", padding: "10px" }}
            >
              Reports
            </h1>
            <section>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log(date);
                  if (!date.from || !date.to) {
                    return toast.error("Please select from and to dates");
                  }
                  getUsers(date);
                }}
              >
                <div className="date">
                  <div style={{ marginRight: "50px", paddingBottom: "20px" }}>
                    <label
                      style={{ marginRight: "10px", padding: "10px" }}
                      htmlFor
                    >
                      From{" "}
                    </label>
                    <input
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) =>
                        setDate({ ...date, from: e.target.value })
                      }
                      type="date"
                      className="date_input"
                    />
                  </div>
                  <div style={{ marginRight: "50px", paddingBottom: "20px" }}>
                    <label
                      htmlFor
                      style={{ marginRight: "10px", padding: "10px" }}
                    >
                      To
                    </label>
                    <input
                      onChange={(e) => setDate({ ...date, to: e.target.value })}
                      max={new Date().toISOString().split("T")[0]}
                      type="date"
                      className="date_input"
                    />
                  </div>
                  <div style={{ marginRight: "50px", paddingBottom: "20px" }}>
                    <button className="primary_btn" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </section>
          </div>
          <div className="student1">
            <Chart
              chartType="Bar"
              data={[
                ["Category", "Count"],
                ["Students", students.length],
                ["Posts", posts.length],
                ["Clubs", clubs.length],
              ]}
              legendToggle
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default SchoolAdminDashboard;
