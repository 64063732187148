/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

function StudentCart() {
    return (
        <div className="app-content">
            <div className="app-content-header" style={{ padding: '20px 0' }}>
                {/* /<h1 class="app-content-headerText">Orders</h1> */}
                {/* <a class="primary_btn" type="button" href="studentStats.html">back</a> */}
            </div>
            <div className="products-area-wrapper tableView">
                <div className="products-header1">
                    <div className="products-row1">
                        <section style={{ marginTop: '100px' }}>
                            <h1 style={{ textAlign: 'center' }}>Manage Your Cart</h1>
                            <div className="cart">
                                <div className="cart_item">
                                    <div>Phone</div><div>$20</div>
                                </div>
                                <div className="cart_item">
                                    <div>Bag</div><div>$20</div>
                                </div>
                                <div className="cart_item">
                                    <div>Food</div><div>$20</div>
                                </div>
                                <div className="cart_checkout">
                                    <button className="primary_btn">  Check out</button><div>Total <br />$60</div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default StudentCart;
