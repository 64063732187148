/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useMemo } from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllAds, getAllProducts } from "../../API";
import Loader from "../../components/Loader";

function BusinessDashboard() {
  const [ads, setAds] = useState(null);
  const [products, setProducts] = useState(null);
  const [user, setUser] = useState(
    JSON.parse(window.localStorage.getItem("user"))
  );
  console.log(user);
  const getAds = async () => {
    try {
      const res = await getAllAds({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setAds(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const getProducts = async () => {
    try {
      const res = await getAllProducts({});
      if (res.reponse === "success" || res.reponse === "SUCCESS") {
        setProducts(res.data);
      } else {
        toast.error("Failed to load page");
      }
    } catch (error) {
      toast.error("Failed to load page");
    }
  };
  const userAds = useMemo(() => {
    if (!ads) {
      return [];
    }
    return ads.filter((ad) => user?.id == ad?.owner_id);
  }, [ads, user?.id]);
  const userProducts = useMemo(() => {
    if (!products) {
      return [];
    }
    return products.filter((product) => user?.id == product?.owner_id);
  }, [products, user?.id]);
  useEffect(() => {
    getAds();
    getProducts();
  }, []);
  return (
    <>
      {ads && products ? (
        <div className="app-content" style={{ overflow: "auto" }}>
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            <h1 className="app-content-headerText">Business Owner Dashboard</h1>
            {/* <a class="primary_btn" type="button" href="studentPost.html">back</a> */}
          </div>
          <div className="products-area-wrapper-dashboard tableView">
            <div className="products-header">
              <div className="products-row1">
                <section>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: "25px",
                    }}
                  >
                    Welcome Business Owner
                  </h1>
                  <div className="student admin_stats">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                      className="admin_card_panel"
                    >
                      <div className="admin_card">
                        {userAds.length} <br /> ADS
                      </div>
                      <div className="admin_card">
                        {userProducts.length} <br /> PRODUCTS
                      </div>
                    </div>
                    <div
                      className="admin_card"
                      style={{ marginInline: "auto", width: "50%" }}
                    >
                      <h1 style={{ marginBottom: "20px" }}>Top Highlights</h1>
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <td>
                              <strong>Top Advertisement</strong> <br />
                            </td>
                            <td>
                              <strong>
                                {userAds[
                                  Math.floor(Math.random() * userAds.length)
                                ]?.name || "No Ads available"}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Top Products</strong>
                              <br />
                            </td>
                            <td>
                              <strong>
                                {userProducts[
                                  Math.floor(
                                    Math.random() * userProducts.length
                                  )
                                ]?.product_name || "No Products available"}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default BusinessDashboard;
