/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPassword } from "../API";
import ErrorMessage from "../components/ErrorMessage";
import Footer from "../components/footer";
import Header from "../components/header";
import projs from "../Js/pro";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!email.trim()) {
      return setError("Email is a required field");
    } else if (!EMAIL_REGEX.test(email)) {
      return setError("Enter a valid email");
    }
    try {
      const res = await resetPassword(email);
      console.log(res);

      if (res.response == "success") {
        toast(res.message);
      } else if (res.response == "failure" || res.reponse == "failure") {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
    setEmail("");
  };
  return (
    <div>
      <script
        src="https://kit.fontawesome.com/82f797382f.js"
        crossOrigin="anonymous"
      ></script>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <link rel="stylesheet" href="owlcarousel/owl.carousel.min.css"></link>
      <script src={projs} defer></script>
      <Header />

      <section>
        <div className="login_wrapper">
          <div className="login">
            <form onSubmit={handleSubmit}>
              <h1>Forgot Password</h1>
              <div className="login_container">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  className="input_box login_input"
                  placeholder="Email.."
                />
                {error && <ErrorMessage message={error} />}
                <button className="primary_btn">Submit</button>
                <span>
                  {" "}
                  <Link to="/login">Login</Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ForgotPassword;
