import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createOrUpdateUser, getAllSchools } from "../../API";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../components/Loader";
import { getRole } from "../../utils/utils";

function EditUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const [schools, setSchools] = useState(null);
  const { data } = location.state;
  console.log(data);
  const initialState = {
    id: data.id,
    name: data.name,
    email: data.email,
    phone: data.phone_number,
    address: data.address,
    role: data.user_type_id,
    password: data.password,
    school: data.schoolId,
  };
  const errorInitialState = {
    name: "",
    email: "",
    phone: "",
    address: "",
    role: "",
    school: "",
  };
  const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const PHONE_REGEX =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const [errors, setErrors] = useState(errorInitialState);
  const [formData, setFormData] = useState(initialState);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({ ...formData, [name]: value });
  };
  const isValidated = () => {
    let tempErrors = {};
    //validation
    if (!formData?.name?.trim()) {
      tempErrors.name = "Name is a required field";
    }
    if (!formData?.email?.trim()) {
      tempErrors.email = "Email is a required field";
    } else if (!EMAIL_REGEX.test(formData.email)) {
      tempErrors.email = "Enter a valid email";
    }
    if (!formData?.phone?.trim()) {
      tempErrors.phone = "Phone number is a required field";
    } else if (!PHONE_REGEX.test(formData.phone)) {
      tempErrors.phone = "Enter a valid phone number";
    }
    if (!formData?.address?.trim()) {
      tempErrors.address = "Address is a required field";
    }

    if (!formData?.role) {
      tempErrors.role = "Please select a role";
    }
    if (!formData?.school) {
      tempErrors.school = "Please select a school";
    }

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0 ? true : false;
  };
  const getSchools = async () => {
    try {
      const res = await getAllSchools({});
      if (res.reponse === "success") {
        setSchools(res.data);
      } else {
        toast.error("Failed to load page");
        navigate("/");
      }
    } catch (error) {
      toast.error("Failed to load page");
      navigate("/");
    }
  };
  useEffect(() => {
    getSchools();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    console.log(formData);
    try {
      const res = await createOrUpdateUser(formData);
      if (res.response === "SUCCESS" || res.response === "success") {
        toast.success("User updated successfully");
        navigate(-1);
      } else if (res.response === "failure" || res.response === "FAILURE") {
        toast(res.Message || "Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <>
      {schools ? (
        <div className="app-content">
          <div className="app-content-header" style={{ padding: "20px 0" }}>
            <h1 className="app-content-headerText">
              {getRole(data.user_type_id)}
            </h1>
            <button
              className="primary_btn"
              type="button"
              onClick={() => navigate(-1)}
            >
              back
            </button>
          </div>
          <div className="products-area-wrapper tableView">
            <section>
              <div className="signup_wrapper">
                <div className="signup">
                  <form onSubmit={handleSubmit}>
                    <h1>{`Edit ${getRole(data.user_type_id)}`}</h1>
                    <div className="login_container">
                      <label htmlFor>Name</label>
                      <input
                        type="text"
                        name="name"
                        defaultValue={formData?.name || ""}
                        value={formData?.name || ""}
                        id="name"
                        className="input_box signup_input"
                        placeholder="Username"
                        onChange={handleInputChange}
                      />
                      {errors.name && <ErrorMessage message={errors.name} />}
                      <label htmlFor>Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        defaultValue={formData?.email || ""}
                        value={formData?.email || ""}
                        className="input_box signup_input"
                        placeholder="Email"
                        onChange={handleInputChange}
                      />
                      {errors.email && <ErrorMessage message={errors.email} />}
                      <label htmlFor>Phone</label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        defaultValue={formData?.phone}
                        value={formData?.phone}
                        className="input_box signup_input"
                        placeholder="Mobile Number"
                        onChange={handleInputChange}
                      />
                      {errors.phone && <ErrorMessage message={errors.phone} />}
                      <label htmlFor="address">Address</label>
                      <textarea
                        className="input_box signup_input"
                        name="address"
                        id="address"
                        rows={4}
                        cols={50}
                        defaultValue={formData?.address}
                        value={formData?.address}
                        onChange={handleInputChange}
                      />
                      {errors.address && (
                        <ErrorMessage message={errors.address} />
                      )}
                      <label htmlFor>Role</label>
                      <select
                        name="role"
                        id="role"
                        defaultValue={formData?.role}
                        value={formData?.role}
                        className="input_box signup_dd"
                        onChange={handleInputChange}
                      >
                        <option value="">Select Role</option>
                        <option value="1">Business Owner</option>
                        <option value="2">School Admin</option>
                        <option value="3">Student</option>
                      </select>
                      {errors.role && <ErrorMessage message={errors.role} />}
                      <label htmlFor="school">School</label>
                      <select
                        defaultValue={formData?.school}
                        value={formData?.school}
                        name="school"
                        id="school"
                        onChange={handleInputChange}
                        className="input_box signup_dd"
                      >
                        <option value="">Select School</option>
                        {schools.map((el) => (
                          <option value={el.School_ID}>
                            {el?.School_Name}
                          </option>
                        ))}
                      </select>
                      {errors.school && (
                        <ErrorMessage message={errors.school} />
                      )}
                      {/* <label htmlFor>Password</label>
                        <input
                          type="password"
                          name="password"
                          defaultValue={1234567}
                          id="password"
                          className="input_box signup_input"
                          placeholder="Password"
                        />
                        <label htmlFor>Confirm Password</label>
                        <input
                          type="password"
                          name="cpassword"
                          id="cpassword"
                          className="input_box signup_input"
                          placeholder="Confirm Password"
                        />
                        <label>Profile Picture</label>
                        <input
                          type="file"
                          name="profile"
                          id="profile"
                          className="input_box signup_input"
                        /> */}
                      <button
                        className="primary_btn"
                        type="submit"
                        id="submit"
                        value="submit"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default EditUser;
