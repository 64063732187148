import { Link } from "react-router-dom";

function SchoolAdminStudent() {
  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">Post</h1>
        <a className="primary_btn" type="button" href="studentPost.html">
          back
        </a>
      </div>
      <div className="products-area-wrapper tableView">
        <div className="products-header">
          <div className="product-cell image">
            Student Name
            <button className="sort-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                />
              </svg>
            </button>
          </div>
          <div className="product-cell image">
            School Name
            <button className="sort-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                />
              </svg>
            </button>
          </div>
          <div className="product-cell image">
            School Admin Name
            <button className="sort-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                />
              </svg>
            </button>
          </div>
          <div className="product-cell image">
            Location
            <button className="sort-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                />
              </svg>
            </button>
          </div>
          <div className="product-cell status-cell">
            Status
            <button className="sort-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                />
              </svg>
            </button>
          </div>
          <div className="product-cell price">
            Action
            <button className="sort-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="products-row">
          <button className="cell-more-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={18}
              height={18}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-more-vertical"
            >
              <circle cx={12} cy={12} r={1} />
              <circle cx={12} cy={5} r={1} />
              <circle cx={12} cy={19} r={1} />
            </svg>
          </button>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>Anusha</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aW50ZXJpb3J8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
              alt="product"
            />
            <span>Boheme</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>School Admin1</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>Texas</span>
          </div>
          {/* <div class="product-cell category"><span class="cell-label">Category:</span>Furniture</div> */}
          <div className="product-cell status-cell">
            <span className="cell-label">Status:</span>
            <span className="status active">Active</span>
          </div>
          {/* <div class="product-cell sales"><span class="cell-label">Sales:</span>11</div> */}
          {/* <div class="product-cell stock"><span class="cell-label">Stock:</span>36</div> */}
          {/* <div class="product-cell price"><span class="cell-label">Price:</span>$560</div> */}
          <div className="product-cell action">
            <span className="cell-label">Action</span>
            <a href="studentProductEdit.html" className="edit_prod">
              <i className="fa fa-pencil" aria-hidden="true" />
            </a>{" "}
            <a
              type="button"
              className="del_prod"
              onclick="return confirm('Are you sure?');"
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="products-row">
          <button className="cell-more-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={18}
              height={18}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-more-vertical"
            >
              <circle cx={12} cy={12} r={1} />
              <circle cx={12} cy={5} r={1} />
              <circle cx={12} cy={19} r={1} />
            </svg>
          </button>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1484154218962-a197022b5858?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8a2l0Y2hlbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
              alt="product"
            />
            <span>Likhitha</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aW50ZXJpb3J8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
              alt="product"
            />
            <span>Dreamy</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>School Admin2</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>Texas</span>
          </div>
          {/* <div class="product-cell category"><span class="cell-label">Category:</span>Kitchen</div> */}
          <div className="product-cell status-cell">
            <span className="cell-label">Status:</span>
            <span className="status disabled">Disabled</span>
          </div>
          {/* <div class="product-cell sales"><span class="cell-label">Sales:</span>6</div> */}
          {/* <div class="product-cell stock"><span class="cell-label">Stock:</span>46</div> */}
          {/* <div class="product-cell price"><span class="cell-label">Price:</span>$710</div> */}
          <div className="product-cell action">
            <span className="cell-label">Action</span>
            <a href="studentPostEdit.html" className="edit_prod">
              <i className="fa fa-pencil" aria-hidden="true" />
            </a>{" "}
            <a
              type="button"
              className="del_prod"
              onclick="return confirm('Are you sure?');"
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="products-row">
          <button className="cell-more-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={18}
              height={18}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-more-vertical"
            >
              <circle cx={12} cy={12} r={1} />
              <circle cx={12} cy={5} r={1} />
              <circle cx={12} cy={19} r={1} />
            </svg>
          </button>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1586023492125-27b2c045efd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60"
              alt="product"
            />
            <span>Kyathi</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aW50ZXJpb3J8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
              alt="product"
            />
            <span>Dreamy</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>School Admin2</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>Texas</span>
          </div>
          {/* <div class="product-cell category"><span class="cell-label">Category:</span>Decoration</div> */}
          <div className="product-cell status-cell">
            <span className="cell-label">Status:</span>
            <span className="status active">Active</span>
          </div>
          {/* <div class="product-cell sales"><span class="cell-label">Sales:</span>61</div> */}
          {/* <div class="product-cell stock"><span class="cell-label">Stock:</span>56</div> */}
          {/* <div class="product-cell price"><span class="cell-label">Price:</span>$360</div> */}
          <div className="product-cell action">
            <span className="cell-label">Action</span>
            <a href="studentProductEdit.html" className="edit_prod">
              <i className="fa fa-pencil" aria-hidden="true" />
            </a>{" "}
            <a
              type="button"
              className="del_prod"
              onclick="return confirm('Are you sure?');"
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="products-row">
          <button className="cell-more-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={18}
              height={18}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-more-vertical"
            >
              <circle cx={12} cy={12} r={1} />
              <circle cx={12} cy={5} r={1} />
              <circle cx={12} cy={19} r={1} />
            </svg>
          </button>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1595526114035-0d45ed16cfbf?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8YmVkcm9vbXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
              alt="product"
            />
            <span>Ashrith</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aW50ZXJpb3J8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
              alt="product"
            />
            <span>Dreamy</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>School Admin2</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>Texas</span>
          </div>
          {/* <div class="product-cell category"><span class="cell-label">Category:</span>Bedroom</div> */}
          <div className="product-cell status-cell">
            <span className="cell-label">Status:</span>
            <span className="status disabled">Disabled</span>
          </div>
          {/* <div class="product-cell sales"><span class="cell-label">Sales:</span>41</div> */}
          {/* <div class="product-cell stock"><span class="cell-label">Stock:</span>66</div> */}
          {/* <div class="product-cell price"><span class="cell-label">Price:</span>$260</div> */}
          <div className="product-cell action">
            <span className="cell-label">Action</span>
            <a href="studentProductEdit.html" className="edit_prod">
              <i className="fa fa-pencil" aria-hidden="true" />
            </a>{" "}
            <a
              type="button"
              className="del_prod"
              onclick="return confirm('Are you sure?');"
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="products-row">
          <button className="cell-more-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={18}
              height={18}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-more-vertical"
            >
              <circle cx={12} cy={12} r={1} />
              <circle cx={12} cy={5} r={1} />
              <circle cx={12} cy={19} r={1} />
            </svg>
          </button>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aW50ZXJpb3J8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
              alt="product"
            />
            <span>John</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aW50ZXJpb3J8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
              alt="product"
            />
            <span>Dreamy</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>School Admin2</span>
          </div>
          <div className="product-cell image">
            <img
              src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="product"
            />
            <span>Texas</span>
          </div>
          {/* <div class="product-cell category"><span class="cell-label">Category:</span>Furniture</div> */}
          <div className="product-cell status-cell">
            <span className="cell-label">Status:</span>
            <span className="status active">Active</span>
          </div>
          {/* <div class="product-cell sales"><span class="cell-label">Sales:</span>32</div> */}
          {/* <div class="product-cell stock"><span class="cell-label">Stock:</span>40</div> */}
          {/* <div class="product-cell price"><span class="cell-label">Price:</span>$350</div> */}
          <div className="product-cell action">
            <span className="cell-label">Action</span>
            <a href="studentProductEdit.html" className="edit_prod">
              <i className="fa fa-pencil" aria-hidden="true" />
            </a>{" "}
            <a
              type="button"
              className="del_prod"
              onclick="return confirm('Are you sure?');"
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolAdminStudent;
