/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { Chart } from "react-google-charts";
import { Link, useLocation, useNavigate } from "react-router-dom";

function AdsRead() {
  const navigate = useNavigate();
  const location = useLocation();
  const ad = location?.state?.ad;
  console.log(ad);
  return (
    <div className="app-content">
      <div className="app-content-header" style={{ padding: "20px 0" }}>
        <h1 className="app-content-headerText">Ad</h1>
        <button
          className="primary_btn"
          type="button"
          onClick={() => navigate(-1)}
        >
          back
        </button>
      </div>
      <div className="products-area-wrapper tableView">
        <div className="products-header">
          <div className="products-row1">
            <section>
              <div className="student">
                <section>
                  <div
                    className="student"
                    style={{
                      backgroundColor: "#fff",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <div className="read_post">
                      <img
                        src="../../assets/club1.png"
                        alt="img"
                        className="read_post_img"
                      />
                      <h1 style={{ padding: "20px 0", fontSize: "40px" }}>
                        {ad?.name}
                      </h1>
                      <p>{ad?.description}</p>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdsRead;
