/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";

function StudentProductRead() {
    return (
        <div className="app-content">
            <div className="app-content-header" style={{ padding: '20px 0' }}>
                <h1 className="app-content-headerText">Product Details</h1>
                <Link type="button" to="/studenthome/studentproducts" className="primary_btn">back</Link>
            </div>
            <div className="products-area-wrapper tableView">
                <div className="products-header">
                    <div className="products-row1">
                        <section>
                            <div className="student">
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default StudentProductRead;
