/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import "./App.css";
import Router from "./components/router";
import { AuthProvider } from "./hooks/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <section className="page_content1">
          <div>
            <Router />
          </div>
        </section>
      </div>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
