/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";

function BusinessOrdersReturn() {
    return (
        <div className="app-content">
            <div className="app-content-header" style={{ padding: '20px 0' }}>
                <h1 className="app-content-headerText">Return Orders</h1>
                <Link className="primary_btn" type="button" to="/businessownerhome/orders">Back to order</Link>
            </div>
            <div className="products-area-wrapper tableView">
                <div className="products-header">
                    <div className="products-row1">
                        <section>
                            <div className="student">
                                <table style={{ width: '100%', textAlign: 'center' }}>
                                    <tbody><tr>
                                        <th>S No</th>
                                        <th>Order ID</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                    </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>ord-123</td>
                                            <td>product 1</td>
                                            <td>2</td>
                                            <td>$200</td>
                                            <td>Collected</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>ord-124</td>
                                            <td>product 2</td>
                                            <td>5</td>
                                            <td>$600</td>
                                            <td>Arrived</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>ord-125</td>
                                            <td>product 3</td>
                                            <td>3</td>
                                            <td>$100</td>
                                            <td>Refund </td>
                                        </tr>
                                    </tbody></table>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default BusinessOrdersReturn;
