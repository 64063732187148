/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { toast } from "react-toastify";
import { createOrUpdateQuery } from "../API/queries";
import contactImg from "../assets/contact.jpg";
import ErrorMessage from "../components/ErrorMessage";
import Footer from "../components/footer";
import Header from "../components/header";
import projs from "../Js/pro";

function Contact() {
  const initialState = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };
  const errorState = {
    name: "",
    description: "",
    phone: "",
    message: "",
  };
  const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const PHONE_REGEX =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState(errorState);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };
  const isValidated = () => {
    let tempErrors = {};
    //validation
    if (!data?.name?.trim()) {
      tempErrors.name = "Name is a required field";
    }
    if (!data?.email?.trim()) {
      tempErrors.email = "Email is a required field";
    } else if (!EMAIL_REGEX.test(data.email)) {
      tempErrors.email = "Enter a valid email";
    }
    if (!data?.phone?.trim()) {
      tempErrors.phone = "Phone number is a required field";
    } else if (!PHONE_REGEX.test(data.phone)) {
      tempErrors.phone = "Enter a valid phone number";
    }

    if (!data?.message?.trim()) {
      tempErrors.message = "Message is a required field";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0 ? true : false;
  };
  const createQuery = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    try {
      const res = await createOrUpdateQuery(data);
      if (res.response === "SUCCESS") {
        toast.success("Query created successfully");
        setData(initialState);
      } else if (res.response === "FAILURE") {
        toast.error(res.Message || "Something went wrong");
        setData(initialState);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setData(initialState);
    }
  };
  return (
    <div>
      <script
        src="https://kit.fontawesome.com/82f797382f.js"
        crossOrigin="anonymous"
      ></script>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <link rel="stylesheet" href="owlcarousel/owl.carousel.min.css"></link>
      <script src={projs} defer></script>
      <Header />
      <section className="page_content">
        <section className="contact_hero_section">
          <div
            className="contact_hero_div"
            style={{ backgroundImage: `url(${contactImg})` }}
          >
            <div className="overlay">
              <h1 className="contact_us">Contact Us</h1>
            </div>
          </div>
        </section>

        <section>
          <div className="contain">
            <div className="wrapper">
              <div className="form">
                <h4>GET IN TOUCH</h4>
                <h2 className="form_headline">Send us a message</h2>
                <form onSubmit={createQuery} id="submit_form">
                  <div className="form_inputs">
                    <div className="forms1">
                      <span>
                        <input
                          name="name"
                          value={data.name}
                          onChange={handleInputChange}
                          id="name"
                          className="input_box input_contact"
                          type="text"
                          placeholder="Your Name*"
                        />
                        {errors.name && <ErrorMessage message={errors.name} />}
                        <small className="name-error"></small>
                      </span>
                      <span>
                        <input
                          name="email"
                          value={data.email}
                          onChange={handleInputChange}
                          id="email"
                          className="input_box input_contact"
                          type="email"
                          placeholder="Your Email*"
                        />
                        {errors.email && (
                          <ErrorMessage message={errors.email} />
                        )}

                        <small className="name-error"></small>
                      </span>
                    </div>
                    <div className="forms2">
                      {/* <span>
                        <input
                          id="company-name"
                          className="input_box input_contact"
                          type="text"
                          placeholder="Company Name*"
                          required
                        />
                        <small className="name-error"></small>
                      </span> */}
                      <span>
                        <input
                          name="phone"
                          value={data.phone}
                          onChange={handleInputChange}
                          id="phone"
                          className="input_box input_contact"
                          type="number"
                          placeholder="Phone Number*"
                          required
                        />
                        {errors.phone && (
                          <ErrorMessage message={errors.phone} />
                        )}

                        <small className="name-error"></small>
                      </span>
                    </div>
                  </div>
                  <p>
                    <textarea
                      name="message"
                      value={data.message}
                      onChange={handleInputChange}
                      minlength="20"
                      id="message"
                      className="input_box"
                      cols="30"
                      rows="7"
                      placeholder="Your Message*"
                      required
                    ></textarea>
                    {errors.message && (
                      <ErrorMessage message={errors.message} />
                    )}

                    <small></small>
                  </p>
                  <p>
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      checked
                    />
                    Yes, I would like to receive communications by call / email
                    about Company's services.
                  </p>
                  <p>
                    <a onClick={createQuery} href="#" className="primary_btn">
                      Submit
                    </a>
                    <button
                      onClick={() => setData(initialState)}
                      className="secondary_btn reset"
                    >
                      Reset
                    </button>
                  </p>
                  <div className="contacts contact_wrapper">
                    <ul>
                      <li>
                        We've driven online revenues of over
                        <span>$2 billion</span> for our clients. Ready to know
                        how we can help you?
                      </li>
                      <span className="hightlight_contact_info">
                        <li className="email_info">
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                          <a href="mailto:superadmin@gmail.com?Subject=Thanks for your interest!">
                            superadmin@gmail.com
                          </a>
                        </li>
                        <li className="mobile_num">
                          <i className="fa fa-phone" aria-hidden="true"></i>
                          <span>+1 123 4567</span>
                        </li>
                      </span>
                    </ul>
                  </div>
                </form>
              </div>

              {/* <div className="contacts contact_wrapper">
                <ul>
                  <li>
                    We've driven online revenues of over
                    <span>$2 billion</span> for our clients. Ready to know how we
                    can help you?
                  </li>
                  <span className="hightlight_contact_info">
                    <li className="email_info">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      <a href="mailto:superadmin@gmail.com?Subject=Thanks for your interest!">superadmin@gmail.com</a>
                    </li>
                    <li className="mobile_num">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      <span>+1 123 4567</span>
                    </li>
                  </span>
                </ul>
              </div> */}
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
