/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useMemo } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createOrder } from "../../API/order";
import ErrorMessage from "../../components/ErrorMessage";
import "./checkoutcart.css";
function Cart() {
  const [user] = useState(JSON.parse(window.localStorage.getItem("user")));
  const [cartItems] = useState(JSON.parse(window.localStorage.getItem("cart")));
  const modifiedCartItems = useMemo(() => {
    let arr = [];
    let idObj = {};
    cartItems.map((item) => {
      if (idObj[item.id]) {
        idObj[item.id] = Number(idObj[item.id]) + 1;
      } else {
        idObj[item.id] = 1;
      }
      return item.id;
    });

    arr = cartItems.map((item) => ({ ...item, quantity: idObj[item.id] }));

    const uniqueArray = arr.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        arr.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });

    return uniqueArray;
  }, [cartItems]);
  const initialState = {
    name: "",
    email: "",
    phone: "",
    address: "",
    role: "",
    city: "",
    state: "",
    zip: "",
    cardName: "",
    creditCard: "",
    expMonth: "",
    expYear: "",
    cvv: "",
  };
  const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const PHONE_REGEX =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const CVV_REGEX = /^[0-9]{3,4}$/;
  const CREDIT_CARD_REGX =
    /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
  const [errors, setErrors] = useState(initialState);
  const [data, setData] = useState(initialState);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };
  const isValidated = () => {
    let tempErrors = {};
    //validation
    if (!data?.name?.trim()) {
      tempErrors.name = "Name is a required field";
    }
    if (!data?.email?.trim()) {
      tempErrors.email = "Email is a required field";
    } else if (!EMAIL_REGEX.test(data.email)) {
      tempErrors.email = "Enter a valid email";
    }

    if (!data?.address?.trim()) {
      tempErrors.address = "Address is a required field";
    }
    if (!data?.city?.trim()) {
      tempErrors.city = "City is a required field";
    }
    if (!data?.state) {
      tempErrors.state = "State is a required field";
    }
    if (!data?.zip) {
      tempErrors.zip = "Zip code is required";
    }
    if (!data?.cardName) {
      tempErrors.cardName = "Card Name is required";
    }
    if (!data?.creditCard) {
      tempErrors.creditCard = "Card Number is required";
    } else if (!CREDIT_CARD_REGX.test(data.creditCard)) {
      tempErrors.creditCard = "Enter valid card number";
    }
    if (!data?.expMonth) {
      tempErrors.expMonth = "Expiry month is required";
    }
    if (!data?.expYear) {
      tempErrors.expYear = "Expiry Year is required";
    } else if (Number(data.expYear) < 2022) {
      tempErrors.expYear = "Enter valid expiry year";
    }
    if (!data?.cvv) {
      tempErrors.cvv = "Cvv is required";
    } else if (!CVV_REGEX.test(data.cvv)) {
      tempErrors.cvv = "enter valid cvv";
    }

    setErrors(tempErrors);
    console.log(tempErrors);

    return Object.keys(tempErrors).length === 0 ? true : false;
  };
  const orderItems = async () => {
    const data = {
      date: new Date().toISOString().split("T")[0],
      id: user.id,
    };
    const items = modifiedCartItems.map((item) => ({
      product_id: item.id,
      price: item.price,
      quantity: item.quantity,
      status: "1",
    }));
    try {
      const res = await createOrder(data, items);
      if (res.response === "success") {
        toast.success(res.message);
        navigate("/studenthome/studentstats/buyingorders");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const proceedtoCheckout = (e) => {
    e.preventDefault();
    if (!cartItems || cartItems.length < 1) {
      return toast("Add atleast one item to check out");
    }
    if (!isValidated()) {
      return;
    }
    orderItems();
  };
  const totalPrice = useMemo(() => {
    if (!cartItems || cartItems.length < 1) return 0;
    return (cartItems || []).reduce((cur, nxt) => {
      return Number(cur) + Number(nxt.price);
    }, 0);
  }, [cartItems]);
  return (
    <div>
      <h2 style={{ marginTop: "20px" }}> Checkout Form</h2>

      <div class="row-1">
        <div class="col-75">
          <div class="container-cart">
            <form onSubmit={proceedtoCheckout}>
              <div class="row-1">
                <div class="col-50">
                  <h3>Shipping Address</h3>
                  <label for="fname">
                    <i class="fa fa-user"></i> Full Name
                  </label>
                  <input
                    name="name"
                    value={data.name}
                    onChange={handleInputChange}
                    type="text"
                    id="fname"
                    placeholder="John M. Doe"
                  />
                  {errors.name && <ErrorMessage message={errors.name} />}
                  <label for="email">
                    <i class="fa fa-envelope"></i> Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={data.email}
                    onChange={handleInputChange}
                    placeholder="john@example.com"
                  />
                  {errors.email && <ErrorMessage message={errors.email} />}
                  <label for="adr">
                    <i class="fa fa-address-card-o"></i> Address
                  </label>
                  <input
                    type="text"
                    id="adr"
                    name="address"
                    value={data.address}
                    onChange={handleInputChange}
                    placeholder="542 W. 15th Street"
                  />
                  {errors.address && <ErrorMessage message={errors.address} />}
                  <label for="city">
                    <i class="fa fa-institution"></i> City
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={data.city}
                    onChange={handleInputChange}
                    placeholder="New York"
                  />
                  {errors.city && <ErrorMessage message={errors.city} />}

                  <div class="row-1">
                    <div class="col-50">
                      <label for="state">State</label>
                      <input
                        type="text"
                        id="state"
                        name="state"
                        value={data.state}
                        onChange={handleInputChange}
                        placeholder="NY"
                      />
                      {errors.state && <ErrorMessage message={errors.state} />}
                    </div>
                    <div class="col-50">
                      <label for="zip">Zip</label>
                      <input
                        type="text"
                        id="zip"
                        name="zip"
                        value={data.zip}
                        onChange={handleInputChange}
                        placeholder="10001"
                      />
                      {errors.zip && <ErrorMessage message={errors.zip} />}
                    </div>
                  </div>
                </div>

                <div class="col-50">
                  <h3>Payment</h3>

                  <label for="cname">Name on Card</label>
                  <input
                    type="text"
                    id="cname"
                    name="cardName"
                    value={data.cardName}
                    onChange={handleInputChange}
                    placeholder="John More Doe"
                  />
                  {errors.cardName && (
                    <ErrorMessage message={errors.cardName} />
                  )}
                  <label for="ccnum">Credit card number</label>
                  <input
                    type="text"
                    id="ccnum"
                    name="creditCard"
                    value={data.creditCard}
                    onChange={handleInputChange}
                    placeholder="1111-2222-3333-4444"
                  />
                  {errors.creditCard && (
                    <ErrorMessage message={errors.creditCard} />
                  )}
                  <label for="expmonth">Exp Month</label>
                  <input
                    type="text"
                    id="expmonth"
                    name="expMonth"
                    value={data.expMonth}
                    onChange={handleInputChange}
                    placeholder="September"
                  />
                  {errors.expMonth && (
                    <ErrorMessage message={errors.expMonth} />
                  )}
                  <div class="row-1">
                    <div class="col-50">
                      <label for="expyear">Exp Year</label>
                      <input
                        type="text"
                        id="expyear"
                        name="expYear"
                        value={data.expYear}
                        onChange={handleInputChange}
                        placeholder="2018"
                      />
                      {errors.expYear && (
                        <ErrorMessage message={errors.expYear} />
                      )}
                    </div>
                    <div class="col-50">
                      <label for="cvv">CVV</label>
                      <input
                        type="text"
                        id="cvv"
                        name="cvv"
                        value={data.cvv}
                        onChange={handleInputChange}
                        placeholder="352"
                      />
                      {errors.cvv && <ErrorMessage message={errors.cvv} />}
                    </div>
                  </div>
                </div>
              </div>

              <input type="submit" value="Continue to checkout" class="btn" />
            </form>
          </div>
        </div>
        <div class="col-25">
          <div class="container-cart">
            <h4>
              Cart{" "}
              <span class="price" style={{ color: "black" }}>
                <i class="fa fa-shopping-cart"></i>{" "}
                <b>{cartItems?.length || 0}</b>
              </span>
            </h4>
            {(modifiedCartItems || []).map((item) => (
              <p key={item.id}>
                <a className="link" href="#">
                  {item.product_name.length > 15
                    ? item.product_name.slice(0, 15) + ".."
                    : item.product_name}
                </a>{" "}
                <span> {`x ${item.quantity || 1}`}</span>
                <span class="price">{`$ ${
                  item.price * (item.quantity || 1)
                }`}</span>
              </p>
            ))}

            <hr />
            <p>
              Total{" "}
              <span class="price" style={{ color: "black" }}>
                <b>{`$ ${totalPrice}`}</b>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Cart;
